/*
	JS for the Account -> Donations list page. This loads via AJAX and allows user to filter the 
	list by charity and Merchant if relevant. The same JS is used on all transaction list pages.
	@author Matthew Norris
*/

const twbsPagination  = require('Vendor/twbs-pagination');

module.exports = Donations;

function Donations() {
	
	this.init = function() {
		self.charityMenu = $("#js-transactions-charity-menu");
		self.merchantMenu = $("#js-transactions-merchant-menu");
		self.giftcardMenu = $("#js-transactions-giftcard-menu");
		self.pageMenu = $("#js-transactions-page-menu");
		self.charityMenu.change(function() { self.ajax(1, true); });
		self.merchantMenu.change(function() { self.ajax(1, true); });
		self.giftcardMenu.change(function() { self.ajax(1, true); });
		self.pageMenu.change(function() { self.ajax(1, true); });
		self.pageSize = 10;
		self.ajax(1, true);
	}
	
	this.initPaging = function(totalPages, startPage) {
		console.log("Donations.initPaging(totalPages="+totalPages+", startPage="+startPage+")");

		var container = $("#transactions-paging-container");
	 	
	 	if (container.length > 0 && container.data("twbs-pagination")) {
		    container.twbsPagination("destroy");
		    container.empty();
	 	}
	 	
	 	$('#transactions-paging').html("<div id=\"transactions-paging-container\"></div>");
        
        $('#transactions-paging-container').twbsPagination({
			totalPages: totalPages,
			visiblePages: 5,
			startPage: 1,
			hideOnlyOnePage: true,
			initiateStartPageClick: false,
			paginationClass: "pagination mt-3 mb-0",
		   	onPageClick: function (event, page) {
				self.ajax(page, false);
			}
		});
	}
	
	this.ajax = function(page, resetPaging) {
		$.ajax("/account/donations/ajax", {
			method: "GET",
			data: {
				"charity-id": self.charityMenu.val(),
				"merchant-id": self.merchantMenu != null ? self.merchantMenu.val() : null,
				"giftcard": self.giftcardMenu != null ? self.giftcardMenu.val() : null,
				"page-id": self.pageMenu != null ? self.pageMenu.val() : null,
				"page": page,
				"size": self.pageSize
			}
		}).done(function(response) {
			$("#js-transactions-loading").collapse("hide");
			$("#js-transactions-list").html(response);
			if (resetPaging) {
				// Deal with pagination
				var totalPages;
				if ($("#js-totals").length == 0) {
					totalPages = 1;
					$("#js-sub-total").hide();
				} else {
					totalPages = Math.ceil($("#js-totals").data("count") / self.pageSize);
					$("#js-sub-total").show().html($("#js-totals").data("header"));
				}
				self.initPaging(totalPages, page);
			}
		})
	}
	
	var self = this;
	self.init();
}