/**
 * This script is responsible for showing currently live streams on fundraising pages.
 * 
 * It polls the server at fixed intervals to see if the given user is online and stops polling when the tab is hidden - this is intentional,
 * we don't want needless requests being made every 5s per tab a user has open of the page.
 *
 * todo: Should not perform new requests whilsts requests are in-flight and should probably have some sort of exponential backoff when requests
 * fail to avoid overwhelming the server (extremely unlikely lol)
 */

const { loadScript } = require('Scripts/common/load-script.js');

const AnalyticsEvent = require('Scripts/common/analytics-event').default;
const TwitchStream   = require('Scripts/donate/streaming/twitch-stream.js');
// const FacebookStream = require('Scripts/donate/streaming/facebook-stream.js');
// const YoutubeStream  = require('Scripts/donate/streaming/youtube-stream.js');

class PageStreamChecker {

	constructor() {
		// UI elements
		this.root = $('.js-page-streams');
		if(!this.root.length) {
			return;
		}
		this.streamOnlineContainer = $('.js-page-streams-online');
		this.streamOfflineContainer = $('.js-page-streams-offline');
		this.streamPlayer = $('#js-page-streams-player');

		this.checkerUrl = this.root.data('checker');
		this.streamProviderName = this.root.data('streamProvider');

		this.currentPoll = null;
		this.isLive = false;
		this.stream = this.getStream();
		this.hasLoaded = false;
		this.interval = this.root.data('interval') || 10;

		this.callSteamProviderSetup();
	}

	bindEvents() {
		$(document).on('visibilitychange', this.onDocumentVisbilityChanged.bind(this));
		this.pollForStatusChanges();
	}

	callSteamProviderSetup() {
		let setupPromise = this.stream.setup();

		if(!(setupPromise instanceof Promise)) {
			throw new Error(`${this.stream.constructor.name} 'setup' method did not return a Promise object.`);
		}

		setupPromise.then(() => {
			console.log('PageStreamChecker().callSteamProviderSetup', 'Stream setup has been performed.');
			this.bindEvents();
		}).catch((e) => {
			console.log('PageStreamChecker().callSteamProviderSetup', 'Stream setup function rejected.');
		});
	}

	// Returns a provider-specific stream object which can be used to perform actions with the stream.
	getStream() {
		if(this.streamProviderName === 'TWITCH') {
			return new TwitchStream();
		} 
		// else if(this.streamProviderName === 'FACEBOOK') {
		// 	return new FacebookStream();
		// } 
		// else if(this.streamProviderName === 'YOUTUBE') {
		// 	return new YoutubeStream();
		// }

		throw new Error(`Unrecognised stream provider name: ${this.streamProviderName}`);
	}

	onDocumentVisbilityChanged() {   		
		if(document.hidden) {
			this.onDocumentHidden();
		} else {
			this.onDocumentVisible();
		}
	}

	onDocumentHidden() {
		console.log('The document is now hidden.', new Date());
		clearInterval(this.currentPoll);
	}

	onDocumentVisible() {
		console.log('The document is now visible.', new Date());
		this.pollForStatusChanges();
	}

	pollForStatusChanges() {
		this.currentPoll = setInterval(() => {
			console.log('Checking if stream is online...');

			$.ajax({
				method: 'GET',
				url: this.checkerUrl
			}).then(resp => {
				this.handleResponse(resp);
			});

		}, this.interval * 1000);
	}

	handleResponse(resp) {
		console.log('Stream check response: ', resp);
		this.isLive = resp.payload && resp.payload.stream;

		let titlePrefix      = "(LIVE) ";
		let currentTitle     = $('head title', window.parent.document);
		let currentTitleText = currentTitle.text().replace(titlePrefix,"");

		if(this.isLive) {
			this.showStreamOnline(resp.payload.stream);	
			currentTitle.text(titlePrefix + currentTitleText);
		} 
		else {
			this.showStreamOffline();
			currentTitle.text(currentTitleText);
		}
	}

	showStreamOnline(streamInfo) {
		console.log("PageStreamChecker.showStreamOnline()", streamInfo);

		this.streamOnlineContainer.collapse('show');
		this.streamOfflineContainer.collapse('hide');

		if(!this.hasLoaded) {
			this.hasLoaded = true;
			this.stream.firstLoad(streamInfo);

			// Analytics
			AnalyticsEvent.facebook('WatchingLiveStream');
			AnalyticsEvent.heap('WatchingLiveStream');
			AnalyticsEvent.gtm('WatchingLiveStream');
		}
	}

	showStreamOffline() {
		console.log("PageStreamChecker.showStreamOffline");

		this.streamOnlineContainer.collapse('hide');
		this.streamOfflineContainer.collapse('show');
		this.streamPlayer.empty();

		this.stream.cleanup();
		this.hasLoaded = false;
	}
}

module.exports = PageStreamChecker;