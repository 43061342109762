/* Navigation turns into a side menu on mobile, this shows/hides it */

export default class MobileMenu {
	constructor() {
		$('[data-toggle="nav-mobile"]').on('click', () => {
			this.toggleMenu();
		});

		$('body').on('click', '.menu-backdrop.show', () => {
			this.toggleMenu();
		});
	}

	toggleMenu(){
		console.log("MobileMenu.toggleMenu");
		
		var nav = $('.nav-mobile-collapse');
		var menuOpened = !nav.hasClass('open'); // If it has the class, it was already open and is therefore being closed

		nav.toggleClass('open', menuOpened);
		$('.nav-toggler').attr('aria-expanded', menuOpened);
		$('.menu-backdrop').toggleClass('show', menuOpened);
		$('body').toggleClass('menu-open', menuOpened);
	}
};