/**
 * Loads groups of JavaScript modules.
 *
 * How it works...
 * 1. You define a map of module name => array of functions that will be called. E.g.
 * 	 {
		"my-module-group": [require("./my-module"), require("./another-module.js")]
 	 }
 * 2. Looks at <body> for data-module-group property.
 * 3. Looks in the map defined in step 1 for the value from step 2
 * 4. If any entry is found, calls each function in the array of functions
 *
 * This allows us to init JS on different pages without having to check paths/query parameters.
 *
 * @author Daniel Moffat
 */

'use strict';

module.exports = {
	getModule: getModule,
	load: load
};

// Contains all of our objects that've been instantiated by load(). You can access them by using getModule(groupId, moduleName)
const loadedModules = {};

/**
 * Loads the modules specified in moduleGroups.
 */
function load(moduleGroups) {
	// Load global modules
	loadModuleGroup('global', moduleGroups)

	// Get the page-specific module groups (comma or space delimited)
	let moduleGroupTag = document.body.getAttribute('data-module-group');

	// If no tag then stop
	if(moduleGroupTag == null) return;

	// Split out the values in the tag
	let groupIds = moduleGroupTag.replace(/,/g,' ').split(' ');

	// Loop through and load each page-specific module
	for (let i = 0; i < groupIds.length; i++) {
		if(groupIds[i]) {
			loadModuleGroup(groupIds[i], moduleGroups);
		}
	}
}

/**
 * Load a module group by its ID
 */
function loadModuleGroup(groupId, moduleGroups) {
	if(!moduleGroups[groupId]) {
		console.warn("No module group with ID: '" + groupId + "' (note: it may be in a different JS file?)");
		console.warn('Groups: ', moduleGroups);
		return;
	}
	
	moduleGroups[groupId].forEach(function(Module) {
		var moduleName = Module.id || Module.name || (typeof Module.default !== 'undefined' ? Module.default.name : null);
		console.log("Creating '" + moduleName + "'");
		
		if(!loadedModules[groupId]) {
			loadedModules[groupId] = {};
		}

		if(Module.default) {
			loadedModules[groupId][moduleName] = new Module.default();
		} else {
			loadedModules[groupId][moduleName] = new Module();
		}
	});
}

/**
 * Helper to fetch a module from a group that's already been instantiated.
 * Lets say you want to call a method on module A from module B, but module A has already been created. You can't create a new instance of A
 * as that will result in undesired effects (e.g. events getting bound twice.). Instead you use this method to get an already created instance of
 * A.
 * Note: Due to minification, function names get removed, so you need to ensure you module has a static 'id' property with the function name in for this to work
 * on test/prod.
 */
function getModule(groupId, moduleName) {
	console.log(`Getting module '${moduleName}' from '${groupId}'`);
	console.log('Currently loaded modules', loadedModules);
	let loadedGroup = loadedModules[groupId];
	if(!loadedGroup) {
		console.warn(`No loaded module group with groupId: ${groupId}`)
		return;
	}
	return loadedGroup[moduleName];
}
