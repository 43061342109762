/**
 * Simple wrapper function for Bootstrap v5 Collapse
 * https://getbootstrap.com/docs/5.2/components/collapse/#via-javascript
 * 
 * Their docs say the old methods (e.g. `$('.foo').collapse()`) still work, but calling on page load can cause issues
 * This is because the DOM is sometimes not ready - using this function therefore avoids having to use $(document).ready() wrappers
 * No idea why this was not an issue with Bootstrap 4!
 * 
 * Example usage:
 * 
 * bsCollapse(".js-element").hide();
 * bsCollapse(".js-element").show();
 * bsCollapse(".js-element").toggle();
 * 
 */

module.exports = bsCollapse;

function bsCollapse(elements) {
	if(typeof elements == "string") {
		elements = $(elements);
	}

	return new CollapseList(elements.map((i, el) => bootstrap.Collapse.getOrCreateInstance(el, { toggle: false })));
}

// This is used by the above function, but isn't exported - allows us to use hide() etc on a list of Bootstrap Collapse objects
class CollapseList {
	constructor(elements) {
		this.elements = elements;
	}

	_valid() {
		return this.elements && this.elements.length;
	}

	show() {
		if(this._valid()) {
			this.elements.each((i, el) => {
				el.show();
			});
		}
	}

	hide() {
		if(this._valid()) {
			this.elements.each((i, el) => {
				el.hide();
			});
		}
	}

	toggle(show) {
		if(this._valid()) {
			this.elements.each((i, el) => {
				if(show === true) {
					el.show();
				}
				else if(show === false) {
					el.hide();
				}
				else {
					el.toggle();
				}
			});
		}
	}
}