const FileSelector   = require('Scripts/common/file-selector').default;
const Form           = require('Scripts/common/form').default;
const TextAreaExpand = require('Scripts/common/text-area-expand');

module.exports = function SupportTicket() {
	this.init = function() {
		console.log('SupportTicket.init')

		this.form = new Form({
			form:     $('#form-support-ticket'),
			always:   this.always.bind(this),
			preCheck: this.preCheck.bind(this),
			callback: this.afterPost.bind(this)
		})

		$('.js-types').on('change', this.changeType.bind(this));

		new FileSelector({
			container: $("#js-support-ticket-attachment")
		});

		// This makes <textarea> inputs expand as you type
		new TextAreaExpand();
	}

	this.changeType = function(e) {
		console.log('changeType', $(e.currentTarget));
		var type = $(e.currentTarget).find(":selected");

		$('#ticket-brand').val(type.data('brand'));
		$(type.data('show')).collapse('show');
		$(type.data('hide')).collapse('hide');
	}

	this.preCheck = function() {
		let recaptcha = this.form.options.form.find('#g-recaptcha-response');
		console.log("SupportTicket.preCheck - recaptcha", !recaptcha.length || recaptcha.val().length ? true : false)
		return !recaptcha.length || recaptcha.val().length;
	}

	/**
	 * This runs even if the submit fails - it resets the Recaptcha value as it cannot be reused
	 */
	this.always = function() {
		if(this.preCheck()) {
			$('#g-recaptcha-response').val('');
		}
	}

	this.afterPost = function(response) {
		console.log("SupportTicket.afterPost", response);
		if(response.success){
			$(".js-support-ticket-success").collapse("toggle");
		}
	}

	let form = $('#form-support-ticket');
	let modal = $('#modal-support-ticket');

	if(form.length) {
		this.init();
	}
	else if (modal.length) {
		modal.on('show.bs.modal', () => this.init());
	}
}