'use strict';

export default class Christmas {
	constructor() {
		if(this.pageIs('/tag')) {
			console.log("Christmas.constructor()", "Tag");
			// Run this on page load
			this.resizeAll();

			// Once resizing done, this helps trigger PDF creation in christmas-gift-tag.js
			$('#gift-tag').addClass('ready');

			// Bindings
			$('#gift-tag-form input').on('keyup', this.update.bind(this));

			// Focus first input - have to do nasty bit to make cursor be at the end
			let inputTo = $('#inputTo');
			inputTo.focus();
			let v = inputTo.val();
			inputTo.val('');
			inputTo.val(v);
		}

		// $('.js-change-tab').on('click', this.changeTab.bind(this));

		$('.js-snowglobe').on('click', this.clickSnowglobe);

		setTimeout(() => {
			this.clickSnowglobe();
		}, 2000);
	}

	pageIs(url) {
		let path = window.location.pathname;
		return path.indexOf(url) > -1;
	}

	// Store category tabs
	// changeTab(e) {
	// 	console.log("Christmas.changeTab()");
	// 	let target     = $(e.currentTarget);
	// 	let colour     = target.data('colour');
	// 	let background = $('.js-background');

	// 	if(background && colour) {
	// 		background.removeClass((i, className) => {
	// 			return (className.match(/(^|\s)bg-\S+/g) || []).join(' ');
	// 		});

	// 		background.addClass(colour);
	// 	}
	// }

	// Shake the snowglobe and make it snow!
	clickSnowglobe() {
		console.log("Christmas.clickSnowglobe");
		let snowglobe = $(".js-snowglobe");
		let shakeCss = "christmas-snowglobe-shake";

		let snow  = snowglobe.find(".snow-waiting");
		let snowCss = "snow";

		snowglobe.addClass(shakeCss);
		snow.addClass(snowCss);

		setTimeout(() => {
			snowglobe.removeClass(shakeCss);
			snow.removeClass(snowCss);
		}, 5000);
	}

	// Resize all the text elements
	resizeAll() {
		this.resize(".to");
		this.resize(".greeting");
		this.resize(".from");
		this.resize(".supporting");
	}

	// Resize single text element (checks if text is bigger/smaller than parent, than adjusts 1px at a time, will usually be slightly smaller)
	resize(parent, decreasing, count) {
		const parentHeight = $(parent).outerHeight();
		const childHeight  = $(parent + " span").outerHeight();
		const fontsize     = parseInt($(parent).css('font-size'));

		// This helps protect against stack trace limits
		count = (count || 0) + 1;

		if(childHeight == 0 || count > 500) return;

		// Very rarely, the text is really tiny - I think because things haven't quite loaded, so we need to watch for that
		if(parentHeight < 10) {
			console.log("Christmas.resize()", "parentHeight is tiny, need to wait for things to load", parentHeight);
			return setTimeout(() => {
				this.resize(parent, decreasing, count);
			}, 500);
		}
		
		console.log("Christmas.resize()", parent + ": " + parentHeight + " vs " + childHeight + " (" + fontsize + "px)"+(decreasing ? "(decreasing)" : ""));
		
		if(childHeight < parentHeight && decreasing != true){
			$(parent).css({'font-size': fontsize + 1 + "px"});
			this.resize(parent, false, count);
		}
		else if(childHeight > parentHeight){
			$(parent).css({'font-size': fontsize - 2 + "px"});
			this.resize(parent, true, count);
		}
		else {
			console.log("Christmas.resize()", "Done");
		}
	}

	// Update the text that is displayed based on what's entered in the form
	update(e) {
		let input = $(e.currentTarget);
		$(input.data('update') + " span").html(input.val());
		this.resizeAll();	
	}
}