// Originally taken from: https://davidwalsh.name/javascript-debounce-function
// Useful for functions triggered by typing - function delays execution of the 
// callback by X milliseconds, but callback will be cancelled if they keep on
// typing. That means only 1 callback runs once they stop typing.

var timeout;

module.exports = function debounce(func, wait = 200) {
	return function() {
		var context = this, args = arguments;

		var later = function() {
			timeout = null;
			func.apply(context, args);
		};

		clearTimeout(timeout);

		timeout = setTimeout(later, wait);
	}
}