/* 
	Show/hide dropdown nav

	We use this instead of Bootstrap's JS because their dropdown.js does a lot more than dropdown navs and includes a dependency of Popper.js (20kb minified) which isn't used for dropdown navs so is just unnecessary

	Hopefully this does the same thing! There is a click eventListener but it toggles on/off depending on if the dropdown is open/closed so should be resource-lite

	13 = ENTER
	27 = ESC
	32 = SPACE
	38 = UP
	40 = DOWN
*/

export default class DropdownMenu {
	constructor() {
		this.nav = $('.nav-container');
		this.hasOpen = false;

		// Bind
		if(this.nav.length){
			this.nav.on('click keydown', '.dropdown-toggle', this.toggle.bind(this));
		}
	}

	// Show or hide the dropdown
	toggle(e) {
		console.log("DropdownMenu.toggle()");

		// Nav item clicked (as opposed to triggered by this.clickOff)
		if(e) {			
			// Toggle menu if ENTER / ESC / SPACE / DOWN pressed
			if(e.type == 'keydown') {
				let allowedKeys = [13, 32];
				if(!this.hasOpen) {
					$.merge(allowedKeys, [27, 40]);
				}

				if($.inArray(e.which, allowedKeys) == -1) {
					// this.upDown(e);
					return;
				}
			}

			var clickedNavLink = $(e.currentTarget); // <a>
			e.preventDefault(); // Stops trying to follow a href. Goes after the above check otherwise keyboard commands go funny
			e.stopPropagation();
		} 
		
		let navLinks = this.nav.find('.dropdown-toggle');
		let hasOpen = false;

		navLinks.each((key, item) => {
			let navLink      = $(item); // <a>
			let navListItem  = navLink.parent('.dropdown'); // parent <li>
			let navDropdown  = navListItem.find('.dropdown-menu'); // child <ul> (or <div> containing <ul>)
			let shouldBeOpen = e && navLink.is(clickedNavLink) && !navListItem.hasClass('show') || false;
			
			navLink.attr('aria-expanded', shouldBeOpen); // <a>
			navListItem.toggleClass('show', shouldBeOpen); // parent <li>
			navDropdown.toggleClass('show', shouldBeOpen); // child <ul> (or <div> containing <ul>)
			
			// Is there a dropdown open?
			if(shouldBeOpen) {
				hasOpen = true;
			}
		});

		// Update global state
		this.hasOpen = hasOpen;

		// If a dropdown is open, then we add a class to <body> which is used for fading out the content
		$('body').toggleClass('dropdown-open', this.hasOpen);

		// Variables for bindings
		let click           = 'click';
		let keydown         = 'keydown';
		let offNamespace    = '.clickOff';
		// let upDownNamespace = '.upDown';

		// If dropdown was closed, then we've just opened it and need to create a listener for clicking off
		if(this.hasOpen) {
			$(document).on(click + offNamespace + ' ' + keydown + offNamespace, this.clickOff.bind(this));
			// $(document).on(keydown + upDownNamespace, this.clickOff.bind(this));
		} 
		// If dropdown has just been closed, then we can stop the listener
		else {
			$(document).off(offNamespace);
			// $(document).off(upDownNamespace);
		}		
	}

	// Check clicks to see if clicking off dropdown (or pressing ESC (27))
	clickOff(e) {
		if(e.type == "click" || e.which == 27) {
			console.log("DropdownMenu.clickOff()");
			let target = $(e.target);
			let dropdown = '.dropdown-menu';
			
			if(!target.closest(dropdown).length && $(dropdown).is(':visible')) {
				this.toggle();
			}
		}
		
	}

	// Pressed UP (38) and DOWN (40) whilst dropdown open
	// TODO
	/*
	upDown(e) {
		if(e.which != 38 && e.which != 40){
			return;
		}

		e.preventDefault();

		let target = $(e.target);
		let links = target.siblings('.dropdown-menu').find('a');

		console.log(target);
		console.log(links);
		links.get(0).focus();

		if(e.which == 38){
			//
		} 
		else if(e.which == 40) {
			//
		}
	}
	*/
};