// JS for the personal details page. Just binds Anonymous switching stuff for the form.

const Form = require("Scripts/common/form").default;

module.exports = function PersonalDetails() {	
	// Anonymous toggle
	$('#anonymous').click(function() {
		var newName = $('#anonymous').is(':checked') ? "Anonymous" : $.trim($('#user\\.firstName').val() + ' ' + $('#user\\.lastName').val());
		console.log("Changing name to " + newName);
		$('#user\\.nickname').val(newName);
	});

	let formEl = $("#form-personal-details");

	if(formEl.length) {
		let endpoint = formEl.attr('action');

		new Form({
			form:     formEl,
			endpoint: endpoint + (endpoint.includes('ajax') ? '' : '/ajax'), // This is so that the form works should JS fail for whatever reason
			callback: (response) => {
				let modal = formEl.parents('.modal');

				if(modal.length) {
					modal.modal('hide');
				}
				else {
					window.location.href = '/dashboard';
				}
			}
		});
	}
};
