/**
 * Basic function for converting an object to FormData for use in fetch POST requests
 * `fetch(endpoint, { method:POST, body: createFormData(data) })`
 */

module.exports = createFormData;

function createFormData(object) {
	let formData = new FormData();

	for(const key in object) {
		formData.append(key, object[key]);
	}

	return formData;
}