/**
 * Show a modal when user is trying to leave page
 * In the VM, trigger by using the #bounce() macro
 *
 * @author Matt Hallett, inspired by Ouibounce but simplified and improved
 */

import Cookie from 'Vendor/js-cookie';

export default class Bounce {
	constructor() {
		this.getModal();

		if(this.modal.length){
			this.setup();
			
			if(this.enabled) {
				setTimeout(() => {
					this.bindEvents();
				}, this.delay);
			}
		}
	}

	// Get the modal from the data-bounce parameter (which is created by #bounce() macro)
	getModal() {
		this.modal = $($("body").data("bounce")) || false;
	}

	// We don't want to show the modal if other modals are open
	bindEvents() {	
		console.log("Bounce.bindEvents");
		$(document).on('mouseleave', this.fire.bind(this));
		$(".modal").on("show.bs.modal", this.disable.bind(this));
		$(".modal").on("hide.bs.modal", this.enable.bind(this));
	}

	// Setup the settings based on data-bounce-xxx settings (also set by the #bounce() macro)
	setup() {
		console.log("Bounce.setup()");

		let body = $("body");

		// Get the cookie name - this allows us to use the bounce tool in lots of places with one cookie per instance rather than one for the lot or one for a group
		this.cookieName = body.data("bounceRef") || "gayl-bounce-" + this.modal.attr("id");
		
		// Get the cookie length (in days)
		this.cookieDays = body.data("bounceDays") || 365;

		// Get the delay (in seconds)
		this.delay = 1000 * (body.data("bounceDelay") || 5);

		// Enable unless there's a cookie already
		this.enable();
	}

	hasFired() {
		return Cookie.get(this.cookieName);
	}

	// Fire the event and show the modal
	fire(e) {
		// Don't fire if sideways exit, if disabled, or if live video playing
		if (e.clientY > 20 || !this.enabled || document.title.indexOf("(LIVE)") > -1 || $('body').hasClass('modal-open')) { return; }

		// Get the modal again - if it's been removed, we need to check for it otherwise it comes back for some reason
		this.getModal();

		console.log("Bounce.fire()", this.modal.length ? "Modal still exists" : "Modal has been removed");
		
		if(this.modal.length) {
			this.modal.modal('show');
			this.enabled = false;
			Cookie.set(this.cookieName, 'true', { expires: this.cookieDays });
		}
	}

	// Enable unless there's a cookie already
	enable() {
		this.enabled = !this.hasFired();
	}

	// What about (LIVE) ?
	// Temporarily disable, e.g. if another modal is open
	disable() {
		this.enabled = false;
	}
}