// Priority-based modal queue class
// Takes IDs of modals to show and triggers the next one after they have been closed.
// This is used on the dashboard, where we often show multiple modals in a sequence (likely annoying the user :/)
// @author Matthew Norris

module.exports = ModalQueue;

function ModalQueue() {
	this.init = function() { 
		if(typeof window.modalQueue !== 'undefined') {
			// If modalQueue exists already, abandon init process, as there should be only one modalQueue
			return
		}

		self.modals = [];
		self.pos = 0;

		// pre-load, attach the modalQueue to the global context so other JS scripts can add modals to the queue as they are loaded and initialised.
		window.modalQueue = this;

		// Check modal preload tag
		var preloadModalName = $('body').data('preloadModal');
		var preloadModal = $('#' + preloadModalName);
		if(preloadModal.length) {
			this.add({
				id: preloadModalName,
				priority: 1
			});
		}

		// This will get called on page load - starts the modal queue
		$(document).ready(() => {
			window.modalQueue.invoke();
		})
	}

	this.add = function(meta) {
		self.modals.push(meta)
	}

	// Call each modal in turn.
	this.invoke = function() {
		console.log("ModalQueue.invoke");

		// Make sure we actually have some modal to show, leave this function if not.
		if (self.modals.length == 0) {
			return;
		}

		// Sort the modals by their priority
		self.modals.sort(function(a, b) {
			if (a.priority < b.priority) {
				return -1;
			} else if (a.priority > b.priority) {
				return 1;
			} else {
				return 0;
			}
		})

		var meta = self.modals.shift(); // Pop some modal info off the front of the queue
		var modal = $("#"+meta.id); // Get the modal DOM element
		modal.on('hidden.bs.modal', self.invoke); // Make sure this function is called again after modal is closed

		// Now show the current modal
		if (meta.function) {
			meta.function() // modal has a special function to activate (e.g. involves AJAX)
		} 
		else if(modal.length) {
			modal.modal('show'); // just a plain modal that can be activated directly using its ID
		}
		else {
			self.invoke();
		}
	}

	var self = this;
	self.init();
}