// Charity consents modal, used on charity choosers and charity pages.
// @author Matthew Norris  

const LoadingButton = require('Scripts/common/loading-button');

var CharityConsentModal = function() {
    this.init = function() {
        // Charity page - click support button
        $(".js-change-charity").on("click", () => {
            this.show();
        }); 

        this.modal = $("#js-modal-charity-email-consent");
        this.form = $("#charity-email-consent__form");
        this.btn = new LoadingButton(this.form.find(".js-submit"));

        // Event binders
        this.modal.find("input:radio").change(this.onChange.bind(this));
        this.form.on("submit", this.onSubmit.bind(this));

        // check at the beginning for need to un-disable the submit button (if pre-selected for some reason)
        this.onChange();
    }

    // Show the modal, happens when a user clicks on something. Leave as a function because some other JS classes reference it.
    this.show = function() {
        this.modal.modal('show');
    }

    // Check radio buttons, undisable when both are clicked, otherwise disable the button
    this.onChange = function() {
        console.log("CharityConsentModal.onChange");

        // If we're using a single checkbox instead of yes/no, 
        // then both of these will be 0 which is fine as we want button enabled
        var nRadios = this.modal.find("input:radio[value=1]").length;
        var nChecked = this.modal.find("input:radio:checked").length;

        this.btn.el().attr("disabled", nRadios != nChecked);
    }

    // Listen for form submit
    this.onSubmit = function() {
        console.log("CharityConsentModal.onSubmit");
        this.btn.disable();
        // Let it propagate - submit handled by browser
    }

    this.init();
}

// Make this work with webpack, but still work on the old site.
if(typeof module !== 'undefined') {
    module.exports = CharityConsentModal;
}