/* Circle Progress */

require('Vendor/jquery-circle-progress');

module.exports = function CircleProgress() {
	// Get the stuff
	var circle   = $('#infobox__progress');
	var perc     = circle.data('perc');
	var width    = Math.floor($(circle).width() / 10) * 10;
	var label    = $(circle).find('b');
	var complete = 0;

	if(circle.length) {
		// Animate the circle then update label
		circle.circleProgress({
			startAngle: -Math.PI/2,
			value: perc,
			size: width,
			lineCap: 'round',
			fill: circle.data('colour') || '#00973a'
		}).on('circle-animation-progress', function(event, animationProgress, stepValue){
			// Have we passed 100% yet?
			if(complete == 0 && stepValue >= 1){
				$(this).addClass('complete');
				complete = 1;
			}
			// Update the label
			var text = Math.floor(stepValue * 100) + '%';
			$(label).html(text);
		});

		// Detect changes
		// Needs to be attr() not data() in order to pick up new value
		var observer = new MutationObserver(mutations => {
			var newPerc = circle.attr('data-perc')
			if(perc != newPerc){
				perc = newPerc;
				circle.circleProgress({value: newPerc});
			}
		});

		observer.observe(circle.get(0), {
			attributes: true //configure it to listen to attribute changes
		});
	}
}