/**
 * This replaces the old hasConsent() function in gdpr.js (now called analytics-loader.js)
 * Now a standalone function so it can be called by other files using 
 * - AnalyticsCheck() - for general check
 * - AnalyticsCheck("social-media") - for checking specific type
 */

const Cookie = require('Vendor/js-cookie');

module.exports = AnalyticsCheck;

function AnalyticsCheck(type) {
	var cookie = Cookie.get("gdpr-accept-privacy-consent");
	var output = !!cookie && (cookie === "true" || type == null || cookie.includes(type));
	console.log("AnalyticsCheck()", type, cookie, output);
	return output;
}