/**
 * Add an error bar to the top of the page
 * Useful for error catching, e.g. see fetchWithErrorHandling()
 */

const bsCollapse = require("Scripts/common/bs-collapse");

export default function showErrorBar(args) {
	console.log("showErrorBar", args);

	// Hide previously shown errors
	bsCollapse($(".sticky-error")).hide();

	const errorDiv = document.createElement("div");
	errorDiv.className = "alert alert-red alert-dismissible band--alert sticky-error collapse";

	const closeButton = document.createElement("button");
	closeButton.className = ("btn-close");
	closeButton.setAttribute("type", "button");
	closeButton.setAttribute("data-bs-dismiss", "alert");
	closeButton.setAttribute("aria-label", "Close");

	const errorInnerDiv = document.createElement("div");
	errorInnerDiv.className = "container";
	errorInnerDiv.innerHTML = args?.text || `Sorry, there's been an error. Please try again. If this continues, please <a href="/contact-us" class="link-block-alert">contact us</a>.`;
	errorInnerDiv.prepend(closeButton);

	errorDiv.appendChild(errorInnerDiv);
	document.body.prepend(errorDiv);

	// Uncollapsing looks a bit nicer than just appearing
	bsCollapse($(errorDiv)).show();

	// When it collapses (when hiding previously shown errors), remove from the DOM
	$(errorDiv).on("hidden.bs.collapse", (e) => {
		e.currentTarget.remove();
	});
}