/**
 * Charity splits modal JS. This allows users to change the % of each donation that goes to their list of selected charities
 *
 * To enable this modal, you need to add the following attributes to a button, and make sure the modal is included on the page (/fundraising/modals/charity-splits.vm):
 * - class="js-charity-splits-modal-trigger"
 *
 * E.g. <button class="js-charity-splits-modal">Update charity splits</button>
 *
 * It will not work if you try and open it like you normally open Bootstrap modals...
 *
 * @author Matt Hallett
 */

'use strict';

const AjaxFormErrorHandler = require('Scripts/common/ajax-form-error-handler');
const LoadingButton        = require('Scripts/common/loading-button');

module.exports = CharitySplits;

function CharitySplits() {
	this.init = function() {
		this.ui = {
			modalTrigger: $('.js-charity-splits-modal-trigger'),
			inputs: $('.js-split-perc'),
			reset: $('.js-charity-splits-reset'),
			rows: $('.js-split-row'),
			saveBtn: $('.js-charity-splits-save'),
			total: $('.js-split-total'),
			totalFail: $('.js-split-total-fail'),
			totalSuccess: $('.js-split-total-success')
		};

		this.splitCount = parseInt(this.ui.modalTrigger.data('splitCount'));

		this.errorHandler = new AjaxFormErrorHandler({useClasses: true});
		this.bindEventHandlers();
	};

	this.bindEventHandlers = function() {
		this.ui.inputs.on('input change', this.onChangeSplit.bind(this));
		this.ui.reset.on('click', this.onReset.bind(this));
		this.ui.saveBtn.on('click', this.onSave.bind(this));

		$("#js-modal-charity-splits").on('show.bs.modal', this.hideStatusMsgs.bind(this));
	};

	// Make sure value is ok, e.g. allowed by the 'step=X' value (usually 10, but MNDA want decimals...)
	this.getValue = function(value, e) {
		value = +parseFloat(value).toFixed(2);

		// Don't round if typing number, otherwise you try to type 33 and you end up with 55!
		if(typeof e !== 'undefined' && e.type == 'input' && $(e.currentTarget).attr('type') == 'number') {
			console.log("Stop getValue", value)
			return value;
		}

		var step = $(e.currentTarget).attr('step');
		var min = $(e.currentTarget).attr('min');

    	return +(Math.max(min, Math.round(value / step) * step)).toFixed(2);
	}

	// Reset the splits so that they are split evenly
	this.onReset = function(e) {
		console.log('Reset to ' + Math.floor(100 / this.splitCount));
		this.ui.inputs.val(Math.floor(100 / this.splitCount));
		this.setTotal(100);
	};

	this.onChangeSplit = function(e) {
		console.log("CharitySplits.onChangeSplit()", e);

		// If they've type a ".", stop (trying to continue causes issues) and wait until they add another decimal place
		if(e.originalEvent.data == ".") return;

		var changedInput = $(e.target);
		var value        = this.getValue(changedInput.val(), e);		
		var cid          = changedInput.data('cid');

		// Change the paired input (there's a text and range for each charity)
		var changedInput = this.inputs(cid);
		changedInput.val(value).data('set', 1);

		console.log('Change split for CID ' + cid + ' by range input to ' + value);

		if(this.splitCount == 2){
			console.log("Just 2 splits, so we can change the other slider");
			this.inputs(cid, 1).val(100 - value);
			return true;
		}

		var rows = this.rows(cid,1);
		var total = value;

		// Find out the grand total
		rows.each(function(e){
			var input = $(this).find("input").first();
			total += parseFloat(input.val());
		});

		console.log("More than 2 splits, so we update the total box and let user adjust values");
		this.setTotal(total);
	}

	this.setTotal = function(total) {
		// Need to cover for both 100 and if they manually type 33/33/33 (or 16/16/16/16/16/16)
		var valid = total == 100 || total == (parseInt(100 / this.splitCount) * this.splitCount);

		// Change the displayed figure (override to 100 if typed 33/33/33 etc)
		this.ui.total.html(valid ? 100 : +(total).toFixed(2));

		// Change red/green and tick/cross
		this.ui.totalFail.toggle(!valid);
		this.ui.totalSuccess.toggle(valid);

		if(valid) {
			this.hideStatusMsgs();
		}
	};

	// Get the rows (pass 1 as the others parameter so that it will get the inputs for the other charities)
	this.rows = function(cid,others) {
		return this.ui.rows.filter('[data-cid' + (others == 1 ? '!' : '') + '=' + cid + ']');
	};

	// Get the inputs (pass 1 as the others parameter so that it will get the inputs for the other charities)
	this.inputs = function(cid,others) {
		return this.rows(cid,others).find('.js-split-perc');
	};

	// Extracts the charity IDs and the split for each one.
	// Returns a map like:
	// {
	//    "charity_id": "33.333"
	// }
	this.getCharitiesAndSplits = function() {
		let out = {};
		let inputs = $('.js-split-perc.form-control');
		inputs.each((idx, el) => {
			el = $(el);
			out[el.data('cid')] = el.val();
		});
		return out;
	};

	this.onSave = function(e) {
		console.log('onSave()');
		var saveBtn = new LoadingButton($(e.currentTarget));
		saveBtn.disable();

		this.save(this.getCharitiesAndSplits()).then(resp => {
			if(resp.success) {
				this.saveSuccess(resp);
			} else {
				this.saveFailed(resp);
			}
		}).always(function() {
			saveBtn.enable();
		});
	};

	this.save = function(data) {
		console.log('save()' , data);
		return $.ajax({
			headers: { // Needed due to @RequestBody annotation
				'Accept': 'application/json',
				'Content-Type': 'application/json' 
			},
			url: this.ui.modalTrigger.data('splitEndpoint'),
			data: JSON.stringify(data),
			method: 'POST'
		});
	};

	this.saveSuccess = function(resp) {
		var successMsg = this.ui.modalTrigger.attr('data-add-success-msg');
		this.showMsg(successMsg, 'success');
	}

	this.saveFailed = function(resp) {
		var errorMsg = this.ui.modalTrigger.attr('data-add-error-msg');
		this.showMsg(errorMsg, 'error');
	};

	// Type = 'success' or 'error'
	this.showMsg = function(msg, type) {
		if(type === 'success') {
			$('.js-charity-splits-success-msg').html(msg);
			this.hideStatusMsgs(true);
			setTimeout(function(){ 
				$('#js-modal-charity-splits').modal('hide');
			}, $('.js-charity-splits-success-msg').length ? 2000 : 0);
		} else {
			this.hideStatusMsgs(false);
			$('.js-charity-splits-error-msg').html(msg);
		}
	};

	this.hideStatusMsgs = function(success) {
		var successDefined = typeof success == 'boolean';
		$('.js-charity-splits-success-msg-container').collapse(successDefined && success ? 'show' : 'hide');
		$('.js-charity-splits-error-msg-container').collapse(successDefined && !success ? 'show' : 'hide');
	};

	this.init();
}