import AnalyticsCheck from 'Scripts/common/analytics-check';

export default class AnalyticsEvent {
	static facebook(name) {
		setTimeout(() => {
			if(AnalyticsCheck("analytics") && typeof fbq !== 'undefined') {
				console.log("AnalyticsEvent", "Send to Facebook", name);
				let standardEvents = ['Donate'];
				fbq(standardEvents.includes(name) ? 'track' : "trackCustom", name);
			}
		}, 0)
	}

	static gtm(name, data) {
		// NB: For this to work, you must have also set up the event within GTM as a trigger and tag
		setTimeout(() => {
			if(AnalyticsCheck("analytics") && typeof window.dataLayer !== 'undefined') {
				console.log("AnalyticsEvent", "Send to GTM", name, {'event': name, ...(data || {})});
				window.dataLayer.push({'event': name, ...(data || {})});
			}
		}, 0)
	}

	static heap(name, data) {
		setTimeout(() => {
			if(AnalyticsCheck("analytics") && typeof heap !== 'undefined') {
				console.log("AnalyticsEvent", "Send to Heap", name, data);
				heap.track(name, data);
			}
		}, 0)
	}

	static redeye(data) {
		if(AnalyticsCheck("analytics") && typeof window.gaylRedeye !== 'undefined') {
			console.log("AnalyticsEvent", "Send to Redeye", data);
			window.gaylRedeye(data);
		}
	}

	static sentry(name, data) {
		setTimeout(() => {
			if(AnalyticsCheck("analytics") && typeof window.Sentry !== 'undefined') {
				console.log("AnalyticsEvent", "Send to Sentry", name, data);
				window.Sentry.captureException(name, data);
			}
		}, 0)
	}
}