module.exports = function TextAreaExpand() {
	$('textarea[data-max-rows]').each((i, el) => {
		console.log("TextAreaExpand()", el);

		let textarea = $(el);
		let minRows  = Number(textarea.attr('rows'));
		let maxRows  = Number(textarea.attr('data-max-rows'));
		let target   = textarea.parent().hasClass("input-group") ? textarea.parent() : textarea;

		// clone the textarea and hide it offscreen
		let textareaClone = $('<textarea/>', {
			rows: minRows,
			maxRows: maxRows,
			tabindex: -1,
			class: textarea.attr('class').replaceAll(/(?:$|\s)js-[^\s]+/ig, "") // Removes any js-foo-bar classes as these are unlikely to be styled and could cause interference
		}).css({
			position: 'absolute',
			left: 9999
		}).insertAfter(target);

		let textareaCloneNode = textareaClone.get(0);

		textarea.off('input.expand').on('input.expand', function () {
			// copy the input from the real textarea
			textareaClone.val(textarea.val());

			// set as small as possible to get the real scroll height
			textareaClone.attr('rows', 1);

			// save the real scroll height
			let scrollHeight = textareaCloneNode.scrollHeight;

			// increase the number of rows until the content fits
			for (let rows = minRows; rows < maxRows; rows++) {
				textareaClone.attr('rows', rows);

				if (textareaClone.outerHeight() >= scrollHeight) {
					break;
				}
			}

			// copy the rows value back to the real textarea
			textarea.attr('rows', textareaClone.attr('rows'));
		});

		// Trigger, unless it's in a modal in which case wait until opened
		let modal = textarea.closest(".modal");

		if(modal.length == 0) {
			textarea.trigger('input');
		}
		else {
			modal.on('shown.bs.modal', () => {
				textarea.trigger('input');		
			});
		}
	});
}
