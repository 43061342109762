/**
 * Offline amounts modal JS. This allows users to add offline amounts to their page - although multiple can be added, their page only displays a grand total
 *
 * To enable this modal, you need to add the following attributes to a button, and make sure the modal is included on the page (/fundraising/modals/offline-amounts/offline-amounts.vm):
 * - class="js-offline-amounts-modal-trigger"
 * - data-id="the-page-id"
 *
 * E.g. <button class="js-offline-amounts-modal" data-id="1234">Manage offline amounts</button>
 *
 * It will not work if you try and open it like you normally open Bootstrap modals...
 *
 * @author Daniel Moffat
 */

'use strict';

const AjaxFormErrorHandler = require('Scripts/common/ajax-form-error-handler');
const LoadingButton        = require('Scripts/common/loading-button');

module.exports = OfflineAmountsModal;

function OfflineAmountsModal() {
	// The page ID being updated.
	this.id = null;

	this.init = function() {
		this.ui = {
			modal: $('#js-modal-offline-amounts'),
			modalBody: $('.js-modal-offline-amounts-body'),
		};
		
		if(!this.ui.modal.length) {
			console.warn('No offline amounts modal found on page, is it included? (offline-amounts.vm). Not doing anything.');
			return;
		}

		this.errorHandler = new AjaxFormErrorHandler({useClasses: true});
		this.bindEventHandlers();
	};

	this.bindEventHandlers = function() {
		// Bind events to the document, so it works for stuff loaded via AJAX.
		$(document).on('click', '.js-offline-amounts-modal-trigger', this.onOpenModal.bind(this));
		// Must use event delegation here, the element doesn't exist on the page until the modal has been loaded.
		this.ui.modalBody.on('submit', '.js-offline-amounts-form', this.onSubmit.bind(this));
		this.ui.modalBody.on('click', '.js-offline-amounts-delete', this.onClickDelete.bind(this));
	};

	this.onOpenModal = function(e) {
		e.preventDefault() // Enable it to be used on <a> elements.

		var clickedEl = $(e.currentTarget);
		this.id = clickedEl.data('id');

		if(!this.id) {
			console.warn('No "data-id" attribute found on modal trigger... not doing anything.');
			return;
		}

		var triggerBtn = new LoadingButton(clickedEl);
		triggerBtn.disable();

		fetch(this.ui.modal.data('endpoint') + '?id=' + this.id)
		.then(response => response.text())
		.then(response => {
			this.ui.modalBody.html(response);
			this.ui.modal.modal('show');

			this.ui.list      = $('.js-offline-amounts-list');
			this.ui.emptyList = $('.js-offline-amounts-list-no-items');
			this.ui.msg       = $('.js-offline-amounts-success-msg');
			this.ui.msgCont   = $('.js-offline-amounts-success-msg-container');

			triggerBtn.enable();
		});
	};

	this.onSubmit = function(e) {
		console.log("OfflineAmountsModal.onSubmit()");

		e.preventDefault();

		var form = e.currentTarget;
		var data = new FormData(form);

		var postBtn = new LoadingButton($(form).find('.js-submit'));
		postBtn.disable();

		data.append('id', this.id);

		fetch(form.action, { body: data, method: 'POST' })
		.then(response => response.json())
		.then(response => {
			if(response.success) {
				this.onAddSuccess(response);
				form.reset();
			} 
			else {
				this.onAddFailed(response);
			}

			postBtn.enable();
		});
	};

	this.onAddSuccess = function(resp) {
		var successMsg = this.ui.modalBody.data('addSuccessMsg');
		this.errorHandler.clear();
		this.addAmountToList(resp.payload.updateHtml);
		this.showSuccessMsg(successMsg);
		this.updateTotal(resp.payload.newGrandTotal);
	}

	this.onAddFailed = function(resp) {
		this.hideSuccessMsg();
		this.errorHandler.handleErrors(resp.payload.validation);
	};

	this.addAmountToList = function(html) {
		this.ui.emptyList.hide();
		var amountList = this.ui.list;
		amountList.prepend(html);
	};

	this.removeAmountFromList = function(id) {
		this.ui.list.find('[data-id="' + id + '"]').closest('.js-offline-amounts').remove();
		if($('.js-offline-amounts').length === 0) {
			this.ui.emptyList.show();
		}
	};

	this.showSuccessMsg = function(msg) {
		this.ui.msg.html(msg);
		this.ui.msgCont.collapse('show');
	};

	this.hideSuccessMsg = function() {
		this.ui.msgCont.collapse('hide');
	};

	this.onClickDelete = function(e) {
		e.preventDefault();
		var clickedEl = $(e.currentTarget);
		var id = clickedEl.data('id');
		var deleteBtn = new LoadingButton(clickedEl);
		deleteBtn.disable();

		fetch(this.ui.list.data('endpoint') + "?offlineAmountId=" + id, { method: 'POST' })
		.then(response => response.json())
		.then(response => {
			if(response.success) {
				this.onDeleteSuccess(response)
			}

			deleteBtn.enable();
		});
	};

	this.onDeleteSuccess = function(resp) {
		var successMsg = this.ui.modalBody.data('deleteSuccessMsg');
		this.removeAmountFromList(resp.payload.id);
		this.showSuccessMsg(successMsg);
		this.updateTotal(resp.payload.newGrandTotal);
	};

	this.updateTotal = function(grandTotal) {
		var grandDisplay   = $('.js-grand-total[data-id=' + this.id + ']');

		if(grandDisplay.length){
			grandDisplay.html(grandTotal);
		}
	}

	var self = this;	
	this.init();
}