/**
 * Re-open page modal JS. This allows users to reopen their page after it's closed.
 *
 * To enable this modal, you need to add the following attributes to a button, and make sure the modal is included on the page (/fundraising/modals/page-open/page-open.vm):
 * - class="js-page-open-modal-trigger"
 * - data-id="the-page-id"
 *
 * E.g. <button class="js-page-open-modal" data-id="1234">Reopen your page</button>
 *
 * It will not work if you try and open it like you normally open Bootstrap modals...
 *
 * @author Daniel Moffat
 */
'use strict';

const AjaxFormErrorHandler = require('Scripts/common/ajax-form-error-handler');
const LoadingButton        = require('Scripts/common/loading-button');

module.exports = PageOpenModal;

function PageOpenModal() {
	// The id of the page being opened.
	this.id = null;

	this.init = function() {
		this.ui = {
			modal: $('#js-modal-page-open'),
			modalBody: $('.js-modal-page-open-body')
		};
		if(!this.ui.modal.length) {
			console.warn('No page open modal found on page, is it included? (page-open.vm). Not doing anything.');
			return;
		}

		this.errorHandler = new AjaxFormErrorHandler();
		this.bindEventHandlers();
	};

	this.bindEventHandlers = function() {
		$(document).on('click', '.js-page-open-modal-trigger', this.onClickModalTrigger.bind(this));
		// Must use event delegation here, the element doesn't exist on the page until the modal has been loaded.
		this.ui.modalBody.on('click', '.js-open-page', this.onClickOpenPageBtn.bind(this));
	};

	this.onClickModalTrigger = function(e) {
		e.preventDefault() // Enable it to be used on <a> elements.
		var clickedEl = $(e.currentTarget);
		this.id = clickedEl.data('id');
		if(!this.id) {
			console.warn('No "data-id" attribute found on modal trigger...not doing anything.');
			return;
		}
		var triggerBtn = new LoadingButton(clickedEl);
		triggerBtn.disable();

		this.load().always(function() {
			triggerBtn.enable();
		});
	};

	this.load = function() {
		var self = this;
		return $.ajax({
			url: '/account/open-page-modal?id=' + this.id
		}).then(function(resp) { // resp is raw HTML.
			self.ui.modalBody.html(resp);
			self.ui.modal.modal('show');
		});
	};

	this.onClickOpenPageBtn = function() {
		console.log('Clicked open button.');
		var self = this;
		var openBtn = new LoadingButton($('.js-open-page'));
		openBtn.disable();
		this.open().then(function(resp) {
			if(resp.success) {
				console.log('Page opened');
				// Reload the current page to trigger the success message.
				window.location.reload();
				self.errorHandler.clear();
			} else {
				console.log('Page failed to be opened');
				self.errorHandler.handleErrors(resp.payload.validation);
			}
		}).always(function() {
			openBtn.enable();
		});
	};

	this.open = function() {
		return $.ajax({
			url: '/account/fundraising-page/open',
			data: {
				id: this.id,
				date: $('.js-open-page-date').val()
			},
			method: 'POST'
		});
	};

	this.init();
}