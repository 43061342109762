// Misc things that should run on every page of every site.

const AnalyticsEvent = require('Scripts/common/analytics-event').default;
const Cookie         = require('Vendor/js-cookie');
const ModalQueue     = require("Scripts/common/modal-queue");

module.exports = function Global() {

	new ModalQueue(); // attaches to window.modalQueue, there should be only one of these objects.

	// Stop multiple modals opening
	$('.modal').on('show.bs.modal', e => {
		$('.modal.show').not(this).each((i, modal) => {
			console.log("Global", "Closing other modals");
			$(modal).modal('hide');
			
			// As open and close events overlap, this class can get lost so manually set
			setTimeout(() => {
				$('body').addClass('modal-open');
			}, 1000);
		});

		// Send modal open event
		AnalyticsEvent.heap('ViewModal', {modalId: e.currentTarget.id});
	});

	// Is it iPad OS? Slightly hacky I know, but the cookie can get picked up by BrowserInformation for a fuller override
	if(navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1 && !Cookie.get("iPadOS")) {
		console.log("Setting iPadOS cookie");
		Cookie.set("iPadOS", true, {expires:365});
	}

	this.grey = function(text) {
		return '<span class="text-grey-50">' + text + '</span>';
	}

	// Global variables
	window.EA = {
		// How long the slide animation takes - used for alert boxes on various forms
		slideMs: 300,

		// Global variables for reports
		reports: {
			// this is what goes into cells where the user's details have been GDPRed out
			anonHtml: this.grey('Anonymous'),

			// very occasionally, teh date field is missing, this is what replaces
			missingHtml: this.grey('No information'),

			// more blank placeholder
			notProvidedHtml: this.grey('Not provided'),
			notFromCpHtml: this.grey('No campaign page'),
			notFromFpHtml: this.grey('No fundraising page'),
			notKnown: this.grey('Not known'),
			
			// Used for corporate free plans
			basicPlan: this.grey('Not available in Basic Plan'),

			// Date format used for everything
			dateFormat: "YYYY-MM-DD"
		}
	};

}