'use strict';

export default class FileSelector {
	constructor(options) {
		this.maxSize    = 10000000;
		this.fileTypes  = [
			'application/gzip',
			'application/msword',
			'application/pdf',
			'application/vnd.ms-excel',
			'application/vnd.ms-powerpoint',
			'application/vnd.openxmlformats-officedocument.presentationml.presentation',
			'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
			'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
			'application/zip',
			'image/bmp',
			'image/gif', 
			'image/jpeg',
			'image/png',
			'image/webp',
			'text/csv',
			'text/plain',
		];
		this.callback   = options.callback || false;

		const container = options.container || $("main");
		
		this.ui = {
			dnd:            container.find('.dragdrop-file'),
			errorContainer: container.find('.js-error-container'),
			fileInput:      container.find('.js-file-input'),
		};

		this.invalidTypeErrorMsg = this.ui.fileInput.data('invalidTypeErrorMsg');
		this.tooBigMsg           = this.ui.fileInput.data('tooBigMsg');
		this.labelText           = container.find('.dragdrop-file-label').html();

		this.bindEventHandlers();
	}

	bindEventHandlers() {
		this.ui.fileInput.on('change', this.onChangeFile.bind(this));

		this.ui.fileInput.on('drag dragstart dragend dragover dragenter dragleave drop', e => {
			this.ui.dnd.addClass("dragdrop-file-hover");
		})
		.on('dragleave dragend drop', e => {
			this.ui.dnd.removeClass('dragdrop-file-hover');
		});
	}

	onChangeFile() {
		console.log("FileSelector.onChangeFile()");

		this.reset(); // Always reset the state after a file has been selected.

		this.selectedFile = this.ui.fileInput.get(0).files[0];

		if(!this.selectedFile) { // User cleared the file input
			return;
		}

		this.updateFileInputLabel('Verifying file');

		// Is file format ok?
		if(!this.isValidContentType(this.selectedFile)) {
			this.showErrorMsg(this.invalidTypeErrorMsg);
			return;
		}

		// Is file size ok?
		if(!this.isValidSize(this.selectedFile)) {
			this.showErrorMsg(this.tooBigMsg);
			return;
		}

		this.postVerify();
	}

	isValidContentType(file) {
		return this.fileTypes.indexOf(file.type) > -1;
	}

	isValidSize(file) {
		return file.size <= this.maxSize;
	}

	postVerify() {
		this.updateFileInputLabel('Selected: ' + this.selectedFile.name);
	}

	// Updates the file input label to the given message
	updateFileInputLabel(msg) {
		var msg = msg || this.labelText;
		var labelEl = this.ui.fileInput.next();
		labelEl.html(msg);
	};

	reset() {
		console.log("FileSelector.reset()");
		this.clearErrorMsg();
		this.updateFileInputLabel();
	}

	showErrorMsg(msg) {
		console.log("FileSelector.showErrorMsg()", msg);
		this.ui.errorContainer.empty().text(msg).show();
		this.updateFileInputLabel();
	}

	clearErrorMsg() {
		console.log("FileSelector.clearErrorMsg()");
		this.ui.errorContainer.empty().hide();
	}

	addFilesToFormData(formData, prepend) {
		console.log("FileSelector.addFilesToFormData()", prepend);

		if(this.selectedFile) {
			prepend = prepend != null ? prepend + "." : "";
			formData.append(prepend, this.selectedFile);
		}

		return formData;
	}
};