/**
 * A function for loading the Twitch JS SDK. Returns a promise that resolves when the script
 * was loaded and rejects if it failed to download for whatever reason.
 *
 * If you call this function multiple times, it will only load the JS once.
 */

const { loadScript } = require('Scripts/common/load-script');

// Keep track of the loaded status
let hasLoaded = false;

const loadTwitchSdk = () => {
	if(hasLoaded) {
		console.log('Twitch SDK has already been loaded on this page.');
		return;
	}

	console.log('Twitch SDK has not been loaded on this page.');
	return loadScript('https://player.twitch.tv/js/embed/v1.js').then(() => {
		hasLoaded = true;
	});
};

module.exports = {
	loadTwitchSdk: loadTwitchSdk
};