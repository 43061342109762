/**
 * Repeat donation list component.
 *
 * Used in the account section (/account/repeat-donations) and on the public charity charity which list fundraising pages.
 *
 * @author Daniel Moffat
 */
'use strict';

const LoadingButton = require('Scripts/common/loading-button');
const Pagination    = require('Scripts/common/pagination');

module.exports = RepeatDonationList;

function RepeatDonationList() {
	this.init = function() {
		this.ui = {
			root: $('.js-repeat-donation-list'),
			content: $('.js-repeat-donation-content'),
			loading: $('.js-repeat-donation-loading')
		};

		this.pagination = new Pagination({
			container: this.ui.root,
			onClickPage: this.onClickPage.bind(this)
		});

		this.page = this.pagination.currentPage;
		this.rows = this.ui.root.data('rows') || 10;
	};

	this.onClickPage = function(page) {
		console.log('Clicked page: ' + page);
		this.page = page;
		this.ui.loading.show();
		this.ui.content.hide();
		this.search();
	};

	this.search = function() {
		var self = this;
		return this.loadResults({
			page: this.page,
			rows: this.rows
		}).then(function(resp) { // resp is raw HTML
			self.ui.loading.hide();
			self.ui.content.empty().html(resp).show();
			self.pagination.bindEventHandlers(); // Rebind the pagination's event handlers
			return resp;
		});
	};

	this.loadResults = function(params) {
		var endpoint = '/account/repeat-donations/list'
		if(params.page) {
			endpoint += '?page=' + params.page;
		}
		if(params.rows) {
			endpoint += '&rows=' + params.rows;
		}
		return $.ajax({
			url: endpoint
		});
	};

	this.init();
}