// Simple animation tool 
// It checks for off-screen elements with an animation and adds a hold class that stops the animation. When scrolled into view, the hold class is removed so the animation can play.
// It is usually best for the animation element to be its own element, just to prevent CSS clashes

class Animate {
	constructor() {
		const animationIsOk = window.matchMedia("(prefers-reduced-motion: no-preference)").matches;

		this.elements = $('.js-animate');

		if(animationIsOk && this.elements.length) {
			this.bindTriggers();
			this.setup();
		}
	}

	bindTriggers() {
		$(window).on("scroll", this.scroll.bind(this));
	}

	setup() {
		console.log("Animate.setup()");

		this.elements.each((i, element) => {
			let el = $(element);
			
			if(this.isOnScreen(el)) {
				el.removeClass("animate-hold");
			}
			else {
				el.addClass("animate-hold");
			}
		});
	}

	scroll() {	
		this.elements.filter(".animate-hold").each((i, element) => {
			let el = $(element);

			if(this.isOnScreen(el)) {
				el.removeClass("animate-hold");
			}
		});
	}

	isOnScreen(el) {
		let distance = el.offset().top - $(window).scrollTop() - $(window).height();
		return distance < 0;
	}
}

module.exports = Animate;