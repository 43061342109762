/**
 * Page update modal JS. This allows users to post updates to their page from a modal, which could live on any page.
 *
 * To enable this modal, you need to add the following attributes to a button, and make sure the modal is included on the page (/fundraising/modals/page-update/page-update.vm):
 * - class="js-page-update-modal-trigger"
 * - data-id="the-page-id"
 *
 * E.g. <button class="js-page-update-modal" data-id="1234">Post an update</button>
 *
 * It will not work if you try and open it like you normally open Bootstrap modals...
 *
 * @author Daniel Moffat
 */
'use strict';

import AjaxFormErrorHandler from 'Scripts/common/ajax-form-error-handler';
import ImageUploadSimple    from 'Scripts/common/image-upload-simple';
import LoadingButton        from 'Scripts/common/loading-button';
import UpdatesList          from 'Scripts/donate/updates-list';

export default class PageUpdateModal {
	constructor() {
		this.id = null;

		this.ui = {
			modal: $('#js-modal-page-update'),
			modalBody: $('.js-modal-page-update-body')
		};

		if(!this.ui.modal.length) {
			console.warn('No page update modal found on page, is it included? (page-update.vm). Not doing anything.');
			return;
		}

		this.errorHandler = new AjaxFormErrorHandler({useClasses: true});
		this.bindEventHandlers();
	}

	bindEventHandlers() {
		// Bind events to the document, so it works for stuff loaded via AJAX.
		$(document).on('click', '.js-page-update-modal-trigger', this.onOpenModal.bind(this));
		// Must use event delegation here, the element doesn't exist on the page until the modal has been loaded.
		this.ui.modalBody.on('submit', '.js-page-update-form', this.onSubmit.bind(this));
		this.ui.modalBody.on('click', '.js-page-update-delete', this.onClickDelete.bind(this));
	};

	onOpenModal(e) {
		e.preventDefault() // Enable it to be used on <a> elements.
		let clickedEl = $(e.currentTarget);
		this.id = clickedEl.data('id');
		
		if(!this.id) {
			console.warn('No "data-id" attribute found on modal trigger...not doing anything.');
			return;
		}
		
		let triggerBtn = new LoadingButton(clickedEl);
		triggerBtn.disable();

		fetch(this.ui.modal.data('endpoint') + '?id=' + this.id)
		.then(response => response.text())
		.then(response => {
			this.ui.modalBody.html(response);
			this.ui.modal.modal('show');

			this.ui.list      = $('.js-page-update-list');
			this.ui.emptyList = $('.js-page-update-list-no-items');
			this.ui.msg       = $('.js-page-update-success-msg');
			this.ui.msgCont   = $('.js-page-update-success-msg-container');

			triggerBtn.enable();

			this.image = new ImageUploadSimple({
				container: $("#js-modal-page-update"),
			});
		});
	}

	onSubmit(e) {
		console.log("PageUpdateModal.onSubmit()");

		e.preventDefault();

		var form = e.currentTarget;
		var formData = new FormData(form);

		var postBtn = new LoadingButton($(form).find('.js-submit'));
		postBtn.disable();

		formData.append('id', this.id);

		fetch(form.action, { body: formData, method: 'POST' })
		.then(response => response.json())
		.then(response => {
			if(response.success) {
				this.onPostSuccess(response);
				form.reset();
				this.image.reset();
			} 
			else {
				this.onPostFailed(response);
			}

			postBtn.enable();
		});
	}

	onPostSuccess(resp) {
		let successMsg = this.ui.modalBody.data('updateSuccessMsg');
		this.errorHandler.clear();
		this.addPageUpdateToList(resp.payload.updateHtml);
		this.showSuccessMsg(successMsg);
		this.injectUpdates();
	}

	onPostFailed(resp) {
		this.hideSuccessMsg();
		this.errorHandler.handleErrors(resp.payload.validation);
	}

	addPageUpdateToList(html) {
		this.ui.emptyList.hide();
		this.ui.list.prepend(html);
	}

	removePageUpdateFromList(id) {
		$('.js-page-update-list').find('[data-id="' + id + '"]').closest('.js-page-update').remove();

		let updateCount = $('.js-page-update').length;

		if(updateCount === 0) {
			this.ui.emptyList.show();
		}
	}

	showSuccessMsg(msg) {
		this.ui.msg.html(msg);
		this.ui.msgCont.collapse('show');
	}

	hideSuccessMsg() {
		this.ui.msgCont.collapse('hide');
	}

	onClickDelete(e) {
		e.preventDefault();
		var clickedEl = $(e.currentTarget);
		var id = clickedEl.data('id');
		var deleteBtn = new LoadingButton(clickedEl);
		deleteBtn.disable();

		fetch(this.ui.list.data('endpoint') + "?pageUpdateId=" + id, { method: 'POST' })
		.then(response => response.json())
		.then(response => {
			if(response.success) {
				this.onDeleteSuccess(id);
			}

			deleteBtn.enable();
		});
	}

	onDeleteSuccess(id) {
		let successMsg = this.ui.modalBody.data('deleteSuccessMsg');
		this.removePageUpdateFromList(id);
		this.showSuccessMsg(successMsg);
		this.injectUpdates();
	}

	// This is for regular fundraising pages only, if you're on your own page and add/delete an update then this updates the page content behind the modal
	injectUpdates() {
		let myUpdates = $("#section-page-updates");
		
		if(myUpdates[0]) {
			return $.ajax({
				url: '/account/updates/list?id=' + this.id
			}).then(resp => { // resp is raw HTML.
				console.log("Injecting updates");
				myUpdates.replaceWith(resp);
				new UpdatesList();
			});
		}
	}
}