/**
 * Pings our server every N seconds to find out how long the user has to complete their ticket purchase
 * 
 * If the time has expired, their basket will be cleared and the tickets made available for purchase by anyone. An alert modal is displayed to inform the user.
 * 
 * Also updates the on-screen timer. Once this hits zero, it forces another update() to ensure everything matches up
 */

import fetchJson from 'Scripts/common/fetch-json';

export default class TicketTimer {
	constructor(opts) {
		this.timer = $(".js-basket-timer");
		this.interval = this.timer.data('interval') || 15;
		this.countdown = null;
		this.modal = $("#js-modal-basket-expired");
		this.resetCallback = opts.resetCallback || null;

		// If there's an active timer, we'll want to ping the server every N seconds
		setInterval(() => {
			if(this.hasActiveTimer()) {
				this.update();
			}
		}, this.interval * 1000);

		// And ping the server on init
		if(this.hasActiveTimer()) {
			this.update();
		}
	}

	hasActiveTimer() {
		return this.timer.attr('data-active') == 'true'; // Needs to use attr() rather than data()
	}

	async update() {
		console.log("TicketTimer.update");
		const timerData = await this.fetchTimerData();
		this.processTimerData(timerData);
	}

	async fetchTimerData() {
		const { result } = await fetchJson("/basket/timer", {
			method: 'POST'
		});

		return result?.payload;
	}

	processTimerData(timerData) {
		console.log("TicketTimer.processTimerData", timerData);

		let secondsRemaining = timerData.secondsRemaining;
		const isRunning = secondsRemaining != null;
		const hasReset = timerData.hasReset;

		this.timer = $(".js-basket-timer"); // Re-set as may not be in initial page load
		this.timer.attr('data-active', isRunning);

		clearInterval(this.countdown);

		this.timer.collapse(isRunning ? 'show' : 'hide');
		this.displayTime(secondsRemaining);

		if(!isRunning) return;

		// Manage the number of seconds remaining until the purchase expires
		this.countdown = setInterval(() => {
			secondsRemaining--;
			this.displayTime(secondsRemaining);

			// Force update when time expires, makes sure frontend and DB are in sync
			if(secondsRemaining <= 0) {
				this.update();
			}
		}, 1000);

		if(hasReset) {
			this.onTimeExpired();
			return;
		}
	}

	displayTime(totalSeconds) {
		totalSeconds = Math.max(totalSeconds, 0);
		const minutes = Math.floor(totalSeconds / 60);
		const seconds = totalSeconds % 60;
		const text = `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
		this.timer.find("samp").html(text);

		const alert = this.timer.find(".alert");
		const baseClass = alert.hasClass("alert-green") ? "alert-green" : "alert-navy";
		alert.toggleClass(baseClass, totalSeconds >= 60);
		alert.toggleClass("alert-red", totalSeconds < 60);
	}

	onTimeExpired() {
		console.log("TicketTimer.onTimeExpired");

		this.modal.modal('show');
		clearInterval(this.countdown);

		if(this.resetCallback) {
			this.resetCallback();
		}
	}

	hide() {
		console.log("TicketTimer.hide");
		this.timer.attr('data-active', 'false');
		this.timer.collapse('hide');
	}
}