/**
 * Update target modal JS. This allows users to update their target once it has been reached
 *
 * To enable this modal, you need to add the following attributes to a button, and make sure the modal is included on the page (/donate/modals/update-target/update-target.vm):
 * - class="js-update-target-modal"
 * - data-id="the-page-id"
 *
 * E.g. <button class="js-update-target-modal" data-id="1234">Update target</button>
 *
 * It will not work if you try and open it like you normally open Bootstrap modals...
 *
 * @author Daniel Moffat
 */
'use strict';

const AjaxFormErrorHandler = require('Scripts/common/ajax-form-error-handler');
const LoadingButton        = require('Scripts/common/loading-button');

module.exports = UpdateTargetModal;

function UpdateTargetModal() {
	// The id being updated.
	this.id = null;

	this.init = function() {
		this.ui = {
			modal: $('#js-modal-update-target'),
			modalBody: $('.js-modal-update-target-body'),
		};
		if(!this.ui.modal.length) {
			console.warn('No update target modal found on page, is it included? (update-target.vm). Not doing anything.');
			return;
		}

		// Show the modal on page load
		if(window.location.search.indexOf('update-target') > -1) {
			var matches = window.location.pathname.match(/\/fundraising\/(.*)$/);
			if(matches) {
				console.log("Auto-showing update target modal for page " + matches[1]);
				this.id = matches[1];
				this.load();
			}
		}

		this.errorHandler = new AjaxFormErrorHandler({useClasses: true});
		this.bindEventHandlers();
	};

	this.bindEventHandlers = function() {
		// Bind events to the document, so it works for stuff loaded via AJAX.
		$(document).on('click', '.js-update-target-modal', this.onClickModalTrigger.bind(this));
		// Must use event delegation here, the element doesn't exist on the page until the modal has been loaded.
		this.ui.modalBody.on('click', '.js-update-target-save', this.onClickUpdate.bind(this));
	};

	this.onClickModalTrigger = function(e) {
		e.preventDefault() // Enable it to be used on <a> elements.
		var clickedEl = $(e.currentTarget);
		this.id = clickedEl.data('id');
		if(!this.id) {
			console.warn('No "data-id" attribute found on modal trigger...not doing anything.');
			return;
		}
		var triggerBtn = new LoadingButton(clickedEl);
		triggerBtn.disable();

		this.load().always(function() {
			triggerBtn.enable();
		});
	};

	this.load = function() {
		var self = this;

		return $.ajax({
			url: '/account/update-target-modal?id=' + this.id
		}).then(function(resp) { // resp is raw HTML.
			self.ui.modalBody.html(resp);
			self.ui.modal.modal('show');
		});
	};

	this.onClickUpdate = function(e) {
		console.log("Clicked update target");

		var self = this;
		var postBtn = new LoadingButton($(e.currentTarget));
		postBtn.disable();

		var data = {
			old: $('#target').data('old'),
			new: $('#target').val(),
		};

		this.update(data).then(function(resp) {
			if(resp.success) {
				self.onUpdateSuccess(resp);
			} else {
				self.onUpdateFailed(resp);
			}
		}).always(function() {
			postBtn.enable();
		});
	};

	// Data is an object in the format:
	// {
	// 		"amount": 100,
	// 		"description": "The description."
	// }
	this.update = function(data) {
		console.log('update()' , data);
		return $.ajax({
			url: '/account/update-target',
			data: {
				id: this.id,
				oldTarget: data.old,
				target: data.new,
			},
			method: 'POST'
		});
	};

	this.onUpdateSuccess = function(resp) {
		console.log("onUpdateSuccess");
		var successMsg = this.ui.modalBody.data('update-success-msg');
		this.errorHandler.clear();
		this.showSuccessMsg(successMsg);
	}

	this.onUpdateFailed = function(resp) {
		console.log("onUpdateFailed");
		this.hideSuccessMsg();
		this.errorHandler.handleErrors(resp.payload.validation);
	};

	this.showSuccessMsg = function(msg) {
		$('.js-update-target-success-msg').html(msg);
		$('.js-update-target-success-msg-container').collapse('show');
		location.reload();
	};

	this.hideSuccessMsg = function() {
		$('.js-update-target-success-msg-container').collapse('hide');
	};

	this.init();
}