import Form from "Scripts/common/form";

export default class LoginSignup {
	constructor(el) {
		if(!el) {
			let forms = $(".js-form-ajax");

			forms.each((i,el) => {
				new LoginSignup(el);
			});

			return;
		}

		let form = $(el);
		let endpoint = form.attr('action');

		this.form = new Form({
			always:       this.always.bind(this),
			callback:     this.afterPost.bind(this),
			endpoint:     endpoint + (endpoint.includes('ajax') ? '' : '/ajax'), // This is so that the form works should JS fail for whatever reason
			form:         form,
			preCheck:     this.preCheck.bind(this),
			willRedirect: true,
		});
	}

	preCheck(e) {
		let recaptcha = this.form.options.form.find('#g-recaptcha-response');
		console.log("LoginSignup.preCheck - recaptcha", !recaptcha.length || recaptcha.val().length ? true : false)
		return !recaptcha.length || recaptcha.val().length;
	}
	
	/**
	 * This runs even if the submit fails - it resets the Recaptcha value as it cannot be reused
	 */
	always(e) {
		if(this.preCheck()) {
			$('#g-recaptcha-response').val('');
		}
	}

	afterPost(response) {
		console.log("LoginSignup.afterPost", response?.payload?.redirectUrl || (response.success ? '/' : window.location));
		window.location.href = response?.payload?.redirectUrl || (response.success ? '/' : window.location);
	}
}
