/**
 * Donation list component.
 *
 * Used in the account section (/account/repeat-donations/{id})
 *
 * @author Daniel Moffat
 */

const Pagination    = require('Scripts/common/pagination');
const TabVisibility = require('Scripts/common/tab-visibility');

class DonationList {
	constructor() {
		this.ui = {
			root: $('.js-donation-list'),
			content: $('.js-donation-list-content'),
			loading: $('.js-donation-list-loading')
		};

		this.pagination = new Pagination({
			container: this.ui.root,
			onClickPage: this.onClickPage.bind(this)
		});

		this.endpoint   = this.ui.root.data('endpoint');
		this.page       = this.pagination.currentPage;
		this.rows       = this.ui.root.data('rows') || 10;
		this.lastIdOrig = this.lastId(); // This is the most recent donation ID when the page loads
		this.lastIdNew  = null; // This is the most recent donation ID when the list refreshes
		this.loading    = false;
		
		if(this.ui.root.hasClass('js-refresh')){
			console.log("Init DonationList.refresh()");
			this.timer;
			this.visible = true;

			new TabVisibility({
				parent: this, 
				callback: () => {
					this.refresh();
				}
			});

			this.refresh();
		}
	}

	setInterval() {
		let active = this.ui.root.data('interval') || 15;
		let inactive = this.ui.root.data('intervalInactive') || 15;
		this.interval = this.visible ? active : inactive;
	}

	onClickPage(page) {
		console.log('DonationList.onClickPage()', page);
		this.page      = page;
		this.loading   = true;
		this.ui.content.parent().css({'height': this.ui.content.height()}); // Hacky, to stop CSS columns-* moving stuff about
		this.ui.loading.show();
		this.ui.content.hide();
		this.search();
		this.ui.content.parent().removeAttr('style'); // And this undoes the hack (if not removed then causes issues)
	}

	search() {
		return this.loadResults().then((html, textStatus, response) => {  // resp is raw HTML
			this.ui.loading.hide();
			this.ui.content.empty().html(html).show();
			this.pagination.bindEventHandlers(); // Rebind the pagination's event handlers
			this.ui.content.find('.collapse').collapse('show'); // Only applies if auto-refreshing
			this.loading = false;
			this.updateTab();

			// Shows the box if we now have some results
			if(html.length > 0){
				this.ui.root.parent(':not(.show)').collapse('show'); 

				this.processAmounts(response);
			}
		});
	}

	loadResults() {
		return $.ajax(this.endpoint + '?page=' + this.page + '&rows=' + this.rows + 
			(this.page == 1 ? 
				(this.lastIdOrig ? '&lastIdOrig=' + this.lastIdOrig : '') + (this.lastIdNew ? '&lastIdNew=' + this.lastIdNew : '') 
			: ''));
	}

	refresh() {
		clearTimeout(this.timer);

		this.setInterval();

		this.timer = setTimeout(() => {
			console.log('DonationList.refresh()', this.interval);
			this.lastIdNew = this.lastId();

			// If we're on page 1, call the search function. The loading value is to stop this running whilst onClickPage is half-done as it causes the 'new' badge to show for all
			if(this.page == 1 && !this.loading) {
				this.search();
			}

			// Repeat the call
			this.refresh();
		}, this.interval * 1000);
	}

	lastId() {
		return this.ui.content.find('article').first().data('id') || null;
	}

	updateTab() {
		// Update the tab title
		let newDonations = this.ui.content.find('.list-box').data('new') || 0;
		let currentTitle = $('head title', window.parent.document);

		if(newDonations != "0" && currentTitle.text().indexOf("(LIVE)") === -1){
			currentTitle.text(currentTitle.text().replace(/^(\([\d\+]+\)\s)?/g, `(${newDonations}) `));
		}
	}

	processAmounts(response) {
		let amounts = {
			'childTotal'   : '.js-child-total',
			'directTotal'  : '.js-direct-total',
			'donateTotal'  : '.js-donate-total',
			'giftAid'      : '.js-giftaid',
			'grandTotal'   : '.js-grand-total',
			'offlineTotal' : '.js-offline-total',
			'targetPerc'   : '.js-target-perc',
			'total'        : '.js-total',
		};

		for(var amount in amounts) {
			let value = response.getResponseHeader(amount);
			
			if(value && amount == 'targetPerc') {
				$(amounts[amount]).attr('data-perc', value);
			}
			else if(value) {
				$(amounts[amount]).html(value)
			}
		}
	}
}

module.exports = DonationList;