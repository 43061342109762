/**
 * JavaScript for the create fundraising page forms. Although multiple steps, all are dealt with here due to common logic.
 * Once all steps have been completed and validated, the data is persisted.
 * 
 * NB. StreamSelector has been commented as currently only Twitch is supported, therefore just using a standard input with no JS needed
 */
'use strict';

const AjaxFormErrorHandler   = require('Scripts/common/ajax-form-error-handler');
const ImageUploadCrop        = require('Scripts/common/image-upload-crop').default;
const ImageUploadGallery     = require('Scripts/common/image-upload-gallery').default;
const LoadingButton          = require('Scripts/common/loading-button');
const Slugify                = require('Vendor/slugify');
// const StreamSelector         = require('Scripts/donate/stream-selector').default; // Uses ES6 module syntax
const Suggestion             = require('Scripts/common/suggestion').default;
const TextAreaExpand         = require('Scripts/common/text-area-expand');
const UriAvailabilityChecker = require('Scripts/donate/uri-availability-checker');

module.exports = CreateFundraisingPageForm;

function CreateFundraisingPageForm() {
	var self = this;

	this.init = function() {
		this.ui = {
			form: $(".js-create-fundraising-page-form"),

			// Containers
			inMemoryContainer:      $('.js-in-memory-container'),
			eventTypeSelect:        $('.js-event-type'),
			eventTypeContainer:     $('.js-custom-event-type-container'),
			eventTypeInput:         $('.js-custom-event-type'),
			dateContainer:          $('.js-date-container'),
			dateRangeContainer:     $('.js-date-range-container'),

			// Inputs
			inputEventDate:      $('#eventDate'),
			inputEventStartDate: $('#eventStartDate'),
			inputEventEndDate:   $('#eventEndDate'),
			inputOwner:          $('#ownerName'),
			inputTarget:         $('#target'),

			// Toggles and buttons
			toggleInMemory:  $('.js-toggle-in-memory'),
			toggleDate:      $('.js-toggle-date'),
			toggleDateRange: $('.js-toggle-date-range'),
			toggleTarget:    $('.js-toggle-target'),
		};
		
		this.ui.submitBtn = new LoadingButton(this.ui.form.find('.js-submit'));
		this.errorHandler = new AjaxFormErrorHandler({ useClasses: true });
		
		// The banner
		this.image = new ImageUploadCrop({
			width:     1170,
			height:    350,
			restrict:  true,
		});

		// The banner gallery 
		this.gallery = new ImageUploadGallery({
			cropper: this.image,
			preview: $(".js-crop-result"),
			previewContainer: $(".js-crop-result-container"),
		});

		// this.streamSelector = new StreamSelector();
		new Suggestion($('.js-set-target'), this.onSetTarget.bind(this));
		new Suggestion($('.js-suggestion'));
		new TextAreaExpand(); // This makes <textarea> inputs expand as you type
		new UriAvailabilityChecker('.js-uri-lookup', '/create/uri-availability');

		this.bindEventHandlers();  	
	}

	this.bindEventHandlers = function() {
		this.ui.form.on('submit', this.onSubmit.bind(this));
		this.ui.toggleInMemory.on('change', this.onToggleInMemory.bind(this));
		this.ui.eventTypeSelect.on('change', this.onChangeEventType.bind(this));
		this.ui.toggleDate.on('change', this.onToggleDate.bind(this));
		this.ui.toggleDateRange.on('change', this.onToggleDateRange.bind(this));
		this.ui.toggleTarget.on('change', this.onToggleTarget.bind(this));
	}

	this.onSubmit = function(e) {
		console.log("CreateFundraisingPageForm.onSubmit()");

		e.preventDefault();
		this.ui.submitBtn.disable();

		fetch($(e.currentTarget).attr('action'), {
			method: 'POST',
			body: this.getFormData(),
		})
		.then(response => {
			if(response.ok) {
				return response.json();
			}
			throw new Error("Not 2xx response")
		}, error => {
			console.error('Error:', error);
		})
		.then(data => this.handleErrors(data))
		.then(resp => {
			if(resp.success) {
				console.log('Submission success', resp.payload.redirectUrl);
				this.ui.submitBtn.success();
				window.location.href = resp.payload.redirectUrl;
			} else {
				console.log('Submission failed');
				this.ui.submitBtn.enable();
			}
		});
	}

	// Manually add some special data to the form
	this.getFormData = function() {
		let formData = new FormData(this.ui.form.get(0));
		formData = this.image.addImagesToFormData(formData, "images");
		// formData = this.streamSelector.addToFormData(formData);
		return formData;
	}

	this.onToggleInMemory = function(e) {
		console.log("CreateFundraisingPageForm.onToggleInMemory");
		let isInMemory = this.ui.toggleInMemory.is("[value=true]:checked");
		this.ui.inMemoryContainer.collapse(isInMemory ? "show" : "hide");
	}

	// Reveals the event type input box when "Something else" is selected from the list.
	this.onChangeEventType = function() {
		console.log("CreateFundraisingPageForm.onChangeEventType()");

		var selected = this.ui.eventTypeSelect.val();

		if(selected === "Something else") {
			this.ui.eventTypeContainer.collapse('show');
			this.ui.eventTypeInput.prop('disabled', false);
		} else {
			this.ui.eventTypeContainer.collapse('hide');
			this.ui.eventTypeInput.prop('disabled', true);
		}
	}

	// Allow user to disable the date
	this.onToggleDate = function() {
		console.log("CreateFundraisingPageForm.onToggleDate()");

		var disabled = this.ui.toggleDate.is(":checked");

		var inputEventDate      = this.ui.inputEventDate;
		var inputEventStartDate = this.ui.inputEventStartDate;
		var inputEventEndDate   = this.ui.inputEventEndDate;

		if(disabled) {
			inputEventDate.data('value', inputEventDate.val() || '');
			inputEventStartDate.data('value', inputEventStartDate.val() || '');
			inputEventEndDate.data('value', inputEventEndDate.val() || '');
		}

		inputEventDate.prop('disabled', disabled).val(disabled ? '' : inputEventDate.data('value') || '');
		inputEventStartDate.prop('disabled', disabled).val(disabled ? '' : inputEventStartDate.data('value') || '');
		inputEventEndDate.prop('disabled', disabled).val(disabled ? '' : inputEventEndDate.data('value') || '');

		if(!disabled) this.onToggleDateRange(); // This corrects the disabled states without having to duplicate code
	}

	// Allow user to switch between single date and start/end dates
	this.onToggleDateRange = function() {
		var toggleDate = this.ui.toggleDate;		
		if(toggleDate.is(":checked")) {
			toggleDate.prop('checked', false).trigger('change');
		}

		var isRange    = this.ui.toggleDateRange.is(":checked");
		var singleDate = this.ui.inputEventDate;
		var rangeDates = this.ui.inputEventStartDate.add(this.ui.inputEventEndDate);

		if(isRange){
			rangeDates.val(singleDate.val());
		} else {
			singleDate.val(rangeDates.first().val());
		}

		singleDate.prop('disabled', isRange);
		rangeDates.prop('disabled', !isRange);

		this.ui.dateContainer.collapse(isRange ? 'hide' : 'show');
		this.ui.dateRangeContainer.collapse(isRange ? 'show' : 'hide');
	}

	this.onSetTarget = function() {
		console.log("CreateFundraisingPageForm.onSetTarget");

		let toggle = this.ui.toggleTarget;
		toggle.prop("checked", false);
		toggle.trigger("change");
	}

	// Allow user to disable the fundraising target. Removes any existing value but remembers it so you can toggle
	this.onToggleTarget = function() {
		var disabled = this.ui.toggleTarget.is(":checked");
		var input = this.ui.inputTarget;

		input.prop('disabled', disabled);

		if(disabled){
			input.data('value',input.val()).val('');
		} else {
			input.val(input.val() || input.data('value') || '');
		}
	};


	this.handleErrors = function(resp) {
		if(!resp.success) {
			this.errorHandler.handleErrors(resp.payload.validation);
			$('.js-uri-lookup-result').show(); // Force the URI message to reappear, gets hidden by AjaxErrorHandler
		} else {
			this.errorHandler.clear();
		}
		return resp;
	}

	this.init();
}