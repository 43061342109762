const { loadTwitchSdk } = require('Scripts/common/load-twitch-sdk');
const Stream            = require('Scripts/donate/streaming/stream.js');

class TwitchStream extends Stream {
	constructor() {
		super();
		console.log('TwitchStream.constructor()');

		this.player     = null;
		this.streamInfo = null;
	}

	setup() {
		console.log('TwitchStream.setup()');

		return loadTwitchSdk().then(() => {
			console.log('Twitch SDK has loaded.');
		});
	}

	firstLoad(streamInfo) {
		console.log('TwitchStream.firstLoad()', streamInfo);
		
		let options = {
			height: "310px",
			width: "100%",
			channel: streamInfo.id,
			allowfullscreen: true,
			parent: [streamInfo.domain]
		};

		this.player = new Twitch.Player("js-page-streams-player", options);
	}

	cleanup() {
		console.log('TwitchStream.cleanup()');

		this.player     = null;
		this.streamInfo = null;
	}
}

module.exports = TwitchStream;