/*!
 * JavaScript for GAYL Donate.
 * Give as you Live Ltd
 */

// Import SASS/SCSS styles
import 'Sass/donate.scss';

// Import common modules
const Animate                   = require("Scripts/common/animate");
const Bounce                    = require("Scripts/common/bounce");
const CharityChooser            = require("Scripts/common/charity-chooser");
const CorporateCharityList      = require('Scripts/common/corporate-charity-list');
const Donations 				= require("Scripts/common/donations");
const DropdownMenu              = require('Scripts/common/nav-dropdown');
const EmailSettings				= require('Scripts/common/email-settings');
const FacebookLogin             = require('Scripts/common/facebook-v2');
const Fees                      = require('Scripts/common/fees');
const FormUtils                 = require('Scripts/common/form-utils');
const GdprConsentModal			= require('Scripts/common/gdpr-consent-modal');
const Global                    = require('Scripts/common/global');
const Loader                    = require('Scripts/common/loader');
const LoginSignup               = require('Scripts/common/login-signup');
const MiniNav                   = require('Scripts/common/mini-nav');
const MobileMenu                = require('Scripts/common/nav-mobile');
const OwlCarousel               = require('Scripts/common/owl-carousel');
const PersonalDetails           = require('Scripts/common/personal-details');
const Share                     = require('Scripts/common/share');
const ShowHide                  = require('Scripts/common/showhide');
const SupportTicket             = require('Scripts/common/support-ticket');

// Import fundraising modules
const CharitySplits                   = require('Scripts/donate/charity-splits');
const Christmas                       = require('Scripts/gayl/christmas');
const CircleProgress                  = require('Scripts/donate/circle-progress');
const CreateFundraisingPageForm       = require('Scripts/donate/create-fundraising-page-form');
const DonationList                    = require('Scripts/donate/donation-list');
const EditFundraisingPageForm         = require('Scripts/donate/edit-fundraising-page-form');
const EventList                       = require('Scripts/donate/event-list');
const OfflineAmountsModal             = require('Scripts/donate/offline-amounts-modal');
const PageCloseModal                  = require('Scripts/donate/page-close-modal');
const PageList                        = require('Scripts/donate/page-list');
const PageOpenModal                   = require('Scripts/donate/page-open-modal');
const PageStreamChecker               = require('Scripts/donate/page-stream-checker');
const PageUpdateModal                 = require('Scripts/donate/page-update-modal');
const RepeatDonationList              = require('Scripts/donate/repeat-donation-list');
const TicketBasket                    = require('Scripts/donate/ticket-basket');
const TicketPurchasesList             = require('Scripts/donate/ticket-purchases-list');
const UpdatesList                     = require('Scripts/donate/updates-list');
const UpdateTarget                    = require('Scripts/donate/update-target');
const Zakat                           = require('Scripts/donate/zakat');

// Define groups of modules - the group name is set in your template ($jsModuleGroup).
// This is just a nicer way to load JS on specific pages without looking at the path/params
// To initiate a group, use the #module macro. E.g. #module("my-group") would add 'my-group' from the groups below.
const moduleGroups = {
	// Available on every page
	'global':                  [Global, DropdownMenu, MobileMenu, OwlCarousel, ShowHide],
	// Available on specific pages
	'animate':                 [Animate],
	'auth':                    [LoginSignup, FacebookLogin],
	'bounce':                  [Bounce],
	'charity-chooser':         [CharityChooser],
	'charity-splits':          [CharitySplits],
	'christmas':               [Christmas],
	'create-fundraising-page': [CreateFundraisingPageForm],
	'dashboard':               [DonationList, Share, GdprConsentModal],
	'donations':               [Donations],
	'edit-fundraising-page':   [EditFundraisingPageForm],
	'email-settings':          [EmailSettings],
	'fees':                    [Fees],
	'form-utils':              [FormUtils],
	'list-fundraising-pages':  [PageList, Share],
	'personal-details':        [PersonalDetails],
	'private-fundraising':     [OfflineAmountsModal, PageCloseModal, PageOpenModal, PageUpdateModal, UpdateTarget],
	'public-campaign':         [DonationList, CircleProgress, MiniNav, PageList, PageStreamChecker, Share, UpdatesList],
	'public-charity':          [DonationList, EventList, MiniNav, PageList, Share],
	'public-corporate':        [CorporateCharityList, DonationList, Share],
	'public-fundraising':      [DonationList, CircleProgress, MiniNav, PageStreamChecker, Share, UpdatesList],
	'public-team':             [CorporateCharityList, PageList, DonationList],
	'public-ticketing':        [MiniNav, Share, UpdatesList],
	'repeat-donation':         [DonationList, RepeatDonationList],
	'select-event':            [EventList],
	'support-ticket':          [SupportTicket],
	'ticket-basket':           [TicketBasket],
	'ticket-purchases-list':   [TicketPurchasesList],
	'zakat':                   [Zakat],
};

Loader.load(moduleGroups);
