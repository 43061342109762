// JS for the email settings form, where a user can change their email address 
// and also their GDPR consents.
// @author Matthew Norris

'use strict';

const buildIcon = require("Scripts/common/build-icon");
const AjaxFormErrorHandler = require('Scripts/common/ajax-form-error-handler');
const LoadingButton = require('Scripts/common/loading-button');

module.exports = function EmailSettings() {
	this.init = function() {
		console.log("EmailSettings.init() invoked")
		self.errorHandler = new AjaxFormErrorHandler();
		self.form = $("#email-settings-form");
		self.btn = new LoadingButton($("#email-settings-form_submit"));
		self.enableForm();
	}

	this.post = function() {
		self.disableForm();	
		var dataStr = self.form.serialize();
		$.ajax("", {
			method: "POST",
			data: dataStr
		}).done(function(response) {
			var errorMessage = $("#email-settings_oops");

			if (self.errorHandler.handleErrors(response)) {
				window.scrollTo(0, 0);
				errorMessage.hide();
				errorMessage.slideDown(self.fadeInMs);
				self.enableForm();
				return;
			}

			errorMessage.hide();
			window.location.href = self.form.data("redirect");
		});
		
		return false;
	}

	this.enableForm = function() {
		self.form.off();
		self.form.on("submit", self.post);
		self.btn.enable();
	}

	this.disableForm = function() {
		self.form.off();
		self.btn.disable();
	}

	var self = this;
	self.init();
};
