/*
 * Handles adding tickets to user's basket on GAYL Donate
 * 3x actions: add, remove and change quantity
 */

import AjaxFormErrorHandler from 'Scripts/common/ajax-form-error-handler';
import Cookie               from 'Vendor/js-cookie';
import createFormData       from 'Scripts/common/create-form-data';
import fetchJson            from 'Scripts/common/fetch-json';
import Fetcher              from 'Scripts/common/fetcher';
import LoadingButton        from 'Scripts/common/loading-button';
import showErrorBar         from 'Scripts/common/show-error-bar';
import TicketTimer          from 'Scripts/donate/ticket-timer';

export default class TicketBasket {
	constructor(opts) {
		this.ui = {
			addBtn: $(".js-basket-add"),
			removeBtn: $(".js-basket-remove"),
			quantityInput: $(".js-basket-quantity"),
			viewBtn: $(".js-basket-view"),
			modal: $("#js-modal-basket"),
			headerBasketBtn: $("header .js-basket-modal"),
		}

		this.callbacks = {
			onTimerReset: opts?.onTimerReset,
			onUpdateBasket: opts?.onUpdateBasket,
		}

		this.timer = new TicketTimer({
			resetCallback: this.onTimerReset.bind(this),
		});

		this.list = new Fetcher({
			element: $("#js-ticket-list-fetcher"),
		});

		this.modal = new Fetcher({
			element: $("#js-basket-modal-fetcher"),
			callback: () => {
				this.timer.update();
			}
		});

		this.content = new Fetcher({
			element: $("#js-basket-fetcher"),
		});

		this.errorHandler = new AjaxFormErrorHandler();
		this.bindEvents();
	}

	onTimerReset() {
		console.log("TicketBasket.onTimerReset");

		this.list.fetch();
		this.content.fetch();
		this.updateHeaderButtonText(0);

		if(this.callbacks.onTimerReset) {
			this.callbacks.onTimerReset();
		}
	}

	bindEvents() {
		this.checkDiscountButton = new LoadingButton($("#js-check-discount-code"));

		// Attached to document so that it works with AJAX content refreshing
		$(document).on('click', ".js-basket-modal", this.showModal.bind(this));
		$(document).on('click', ".js-basket-add", this.onAddToBasket.bind(this));
		$(document).on('click', ".js-basket-remove", this.onRemoveFromBasket.bind(this));
		$(document).on('change', ".js-basket-quantity", this.onChangeQuantity.bind(this));
		$(document).on('keydown', '#code', this.onTypeDiscountCode.bind(this));
		$(document).on('click', "#js-check-discount-code", this.onCheckDiscountCode.bind(this));
	}

	async onAddToBasket(e) {
		console.log("TicketBasket.onAddToBasket");

		e.preventDefault();
		const btn = new LoadingButton(e.target);
		btn.disable();

		await this.postBasketData("/add", btn.el().data())

		btn.enable();
	}

	async onRemoveFromBasket(e) {
		console.log("TicketBasket.onRemoveFromBasket");

		e.preventDefault();
		const btn = new LoadingButton(e.target);
		btn.disable();

		await this.postBasketData("/remove", btn.el().data())

		btn.enable();
	}

	async onChangeQuantity(e) {
		console.log("TicketBasket.onChangeQuantity");

		const data = $(e.target).data();
		const oldQuantity = $(e.target).data('originalCount');
		const newQuantity =  $(e.target).val();
		const diff = newQuantity - oldQuantity;

		data['ticketCount'] = Math.abs(diff);

		if(diff > 0) {
			this.postBasketData("/add", data);
		} else if (diff < 0) {
			this.postBasketData("/remove", data);
		}
	}

	showModal() {
		console.log("TicketBasket.showModal");
		this.modal.fetch();
		this.ui.modal.modal('show');
	}

	updateModalAndContent() {
		console.log("TicketBasket.updateModalAndContent");
		this.showModal();
		this.list.fetch();
		this.content.fetch();
		this.dropCookie();
		this.timer.update();
	}

	async postBasketData(endpoint, data) {
		const requestBody = createFormData(data);

		const { success, result } = await fetchJson("/basket" + endpoint, {
			method: 'POST',
			body: requestBody,
		});

		if(!success) {
			showErrorBar();
			return;
		}

		this.errorHandler.handleErrors(result.payload.validation);

		this.updateModalAndContent();

		this.updateHeaderButtonText(result.payload.basket.count);

		if(this.callbacks.onUpdateBasket) {
			this.callbacks.onUpdateBasket(result.payload.basket);
		}
	}

	onTypeDiscountCode(e) {
		const keycode = e.charCode || e.keyCode || 0;

		if(keycode == 13) {
			e.preventDefault();
			this.checkDiscountButton.button.trigger('click');
		}
	}

	async onCheckDiscountCode(e) {
		console.log("TicketBasket.onCheckDiscountCode", e);

		this.checkDiscountButton.disable();
		
		const requestBody = createFormData({
			ticketingPageId: $(e.currentTarget).data('ticketingPageId'),
			code: $($(e.currentTarget).data('target')).val(),
		});

		const { success, result } = await fetchJson("/basket/apply-discount-code", {
			method: 'POST',
			body: requestBody,
		});

		this.checkDiscountButton.enable();

		if(!success) {
			showErrorBar();
			return;
		}

		if(!result.success) {
			this.errorHandler.handleErrors(result.payload.validation, {disableScroll:true});
			return;
		}

		this.updateModalAndContent();
	}

	updateHeaderButtonText(basketCount) {
		console.log("TicketBasket.updateHeaderButtonText", basketCount);

		this.ui.headerBasketBtn.each((i,el) => {
			const counter = $(el).find(".js-basket-count");
			counter.html(counter.html().replaceAll(/\d+/g, basketCount));

			if(basketCount > 0) {
				counter.show();
			} else {
				counter.hide();
			}
		});
	}

	dropCookie() {
		console.log("TicketBasket.dropCookie");
		Cookie.set('gayl-viewed-ticketing', true, { expires: 1/48 }); // 30 minutes
	}
}