module.exports = TabVisibility;

function TabVisibility(opts) {
	let stateKey, eventKey, keys = {
		hidden: "visibilitychange",
		webkitHidden: "webkitvisibilitychange",
		mozHidden: "mozvisibilitychange",
		msHidden: "msvisibilitychange"
	};

	for (stateKey in keys) {
		if (stateKey in document) {
			eventKey = keys[stateKey];
			break;
		}
	}

	if(!eventKey) return;

	opts.parent.visible = !document[stateKey];

	document.addEventListener(eventKey, () => {
		opts.parent.visible = !document[stateKey];

		opts.callback;

		if(opts.callback) {
			opts.callback();
		}
	});
}