/**
 * Owl Carousel initialiser
 */

// Import core OwlCarousel file
require('Vendor/owl.carousel/src/js/owl.carousel.js')

// Import specific OwlCarousel plugins (we only want certain ones)
require('Vendor/owl.carousel/src/js/owl.autoplay.js')
require('Vendor/owl.carousel/src/js/owl.navigation.js')
require('Vendor/owl.carousel/src/js/owl.support.js')
// require('Vendor/owl.carousel/src/js/owl.lazyload.js') // Let browser handle with loading="lazy" instead
// require('Vendor/owl.carousel/src/js/owl.animate.js')
// require('Vendor/owl.carousel/src/js/owl.autoheight.js')
// require('Vendor/owl.carousel/src/js/owl.autorefresh.js')
// require('Vendor/owl.carousel/src/js/owl.hash.js')
// require('Vendor/owl.carousel/src/js/owl.support.modernizr.js')
// require('Vendor/owl.carousel/src/js/owl.video.js')

// Used for building FontAwesome icons
const buildIcon = require("Scripts/common/build-icon");

module.exports = OwlCarousel;

function OwlCarousel() {

	// Find each slider on the page, pull some attributes then initialise the slider
	$('.js-slider').each(function() {
		initOwlCarousel(this);
	});

	// If carousel is behind tab, show once it is visible.
	$('[data-bs-toggle="tab"]').on('shown.bs.tab', function(e) {
		var tabId = $(e.currentTarget).attr("href"); // get the id of the tab panel
		var tabPanelElement = $(tabId + " .js-slider"); // find the js-slider child element - the owl carousel entity
		if (tabPanelElement.length > 0) { // if it exists, carry out carousel initialisation
			initOwlCarousel(tabPanelElement);
		}
	});

	// If carousel is in a modal, attach owl carousel intialisation to the modal shown event. This means that the carousel will initialise after the modal show animation.
	$(".modal").each(function() {
		$(this).on('shown.bs.modal', function () {
			$(this).find(".js-slider").each(function() {
				console.log("OwlCarousel - init in modal", $(this));
				initOwlCarousel($(this));
			});
		});
	})

	// Attach to window so that init can be called outside of this thing - needed for badge modal carousel
	window.initOwlCarousel = initOwlCarousel;

	// The initialise stuff
	function initOwlCarousel(e) {
		var carousel = $(e);
		var data = carousel.data();

		// Don't run if hidden, e.g. behind tab
		if($(carousel).parent().is(":hidden")){
			return false;
		}

		// Just to save a few characters when minified as words are repeated a bit below
		var left  = "left";
		var right = "right";

		// Set the items
		var items         = data.items;
		var itemsDesktop  =                     items || 6;
		var itemsTablet   = data.itemsTablet || items || 3;
		var itemsMobile   = data.itemsMobile || items || 2;
		    
		// Show nav
		var showNav       = data.nav;
		    showNav       = typeof showNav != 'undefined' ? showNav : "true";

		// Arrows FontAwesome element
		function owlArrows(direction, size){
			return buildIcon('fa-angle-'+direction, {title: (direction == left ? 'Previous' : 'Next'), classes: (size ? ' fa-' + size : ''), tight:true});
			// If I wanted to use arrows in circles
			// return buildIcon('fa-angle-'+direction, {title: (direction == left ? 'Previous' : 'Next'), size: (size ? ' fa-' + size : ''), circle:true});
		}

		carousel.owlCarousel({
			autoplay:           data.autoPlay,
			autoplayHoverPause: true,
			smartSpeed:         data.autoPlaySpeed || 250,
			autoplayTimeout:    data.autoPlayTimeout || 5000,
			dots:               data.dots,
			items:              itemsDesktop,
			loop:               data.loop,
			nav:                showNav,
			responsiveClass:    true,
			responsive: {
				0: {
					items: itemsMobile,
					navText: [owlArrows(left), owlArrows(right)]
				},
				480: {
					items: Math.floor((itemsMobile + itemsTablet) / 2),
					navText: [owlArrows(left), owlArrows(right)]
				},
				768: {
					items: itemsTablet,
					navText: [owlArrows(left, 'xl'), owlArrows(right, 'xl')]
				},
				992: {
					items: Math.floor((itemsTablet + itemsDesktop) / 2),
					navText: [owlArrows(left, 'xl'), owlArrows(right, 'xl')]
				},
				1200: {
					items: itemsDesktop,
					navText: [owlArrows(left, 'xl'), owlArrows(right, 'xl')]
				}
			},
			onInitialized: function() {
				this.$element.find(".owl-dot").attr('aria-label','Change slide'); // Helps our accessibility score
				this.$element.find(".owl-prev,.owl-next").attr('role', 'button'); // Helps our accessibility score
			}
		});
	}
}