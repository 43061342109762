/* Share modal */

// todo(dan): Update this so it uses the common load-facebook SDK function

const AnalyticsCheck = require('Scripts/common/analytics-check');
const bsCollapse     = require('Scripts/common/bs-collapse');
const Clipboard      = require('Vendor/clipboard');
const Cookie         = require('Vendor/js-cookie');
const LoadingButton  = require('Scripts/common/loading-button');
const miuri          = require('Vendor/miuri.js').miuri;
const ModalQueue     = require("Scripts/common/modal-queue");

module.exports = Share;

function Share(modal) {
	this.fbConsent = false;
	this.fbAppId = parseInt($("meta[property='fb:app_id']").attr("content"));
	this.facebookSdkLoaded = false;
	this.clipboardLoaded = false;

	this.init = function(modal) {
		this.ui = {
			modal: $(modal || '#modal-share'),
			modalBody: $('.js-modal-share-body'),
			shareButton: $('.js-share-action'),
		};

		if(!this.ui.modal.length) {
			console.warn('No share modal found on page, is it included?');
			return;
		}

		this.loadFacebookSdk();
		this.bindEventHandlers();
		this.setupClipboard();

		// Show share modal on page load (needs the "?" in the indexOf in case "share" is included in any UTM parameters)
		if(window.location.search.indexOf('?share') > -1 && this.ui.modalBody.html().length > 10) {
			console.log('Open modal automatically');
			new ModalQueue();
			window.modalQueue.add({ id: this.ui.modal.attr('id') });
		} else {
			var urlParams = new URLSearchParams(window.location.search);
			var shareId = urlParams.get('share');
			if(shareId) {
				setTimeout(() => {
					var btn = $(".js-open-share-modal[data-id="+shareId+"]");
					btn.trigger('click');
				}, 100);
			}
		}
	}

	this.bindEventHandlers = function() {
		// Event will duplicate otherwise... as share links can be in various AJAX content (e.g. updates), this method avoids us having to rebind in other places
		var triggerClass = '.js-open-share-modal';
		$(document).off('click', triggerClass);
		$(document).on('click', triggerClass, this.onOpenModal.bind(this));

		this.ui.shareButton.off().on('click', this.share.bind(this));
	}

	// We use ClipboardJS to copy the link
	this.setupClipboard = function() {
		if(!Clipboard.isSupported() || this.clipboardLoaded) { return; }

		var copied = new Clipboard('.js-copy');

		copied.on('success', e => {
			console.log("Copied: " + e.text);

			e.clearSelection();
			var successTarget = $(e.trigger).data('clipboardSuccess');

			if(successTarget){
				$(successTarget).collapse('show');

				// Not used atm
				// if($(e.trigger).data('clipboardHide')){
				// 	setTimeout(function() {
				// 		$(successTarget).collapse("hide");
				// 	}, 3000);
				// }
			}

			if($(e.trigger).hasClass('js-share-action')) return; // Otherwise track() runs twice
			this.track(null, $(e.trigger).data('platform') || 'url');
		});

		// This stops duplicate events happening when load() re-inits this class
		this.clipboardLoaded = true;
	}

	// Open the modal when button is pressed
	this.onOpenModal = function(e) {
		e.preventDefault() // Enable it to be used on <a> elements.

		var clickedEl = $(e.currentTarget);
		this.query    = clickedEl.data();
		
		if(!this.query.id) {
			console.warn('No "data-id" attribute found on modal trigger...not doing anything.');
			return;
		}

		// Hide confirm messages
		bsCollapse(this.ui.modal.find(".js-copy-success")).hide();

		// Ajax in modal content
		this.load(e);
	}

	// Get modal content via AJAX
	this.load = function(e) {
		var button = new LoadingButton($(e.currentTarget));
		button.disable();
		
		var endpoint = new miuri(this.ui.modal.data("endpoint"));
		endpoint.query(this.query);
		
		return $.ajax({
			url: endpoint
		}).then(function(resp) { // resp is raw HTML.
			self.ui.modalBody.html(resp);
			self.ui.modal.modal('show');
			self.init();
			button.enable();
		});
	};

	// Load FB SDK so we can share to Facebook and Messenger
	this.loadFacebookSdk = function() {
		// Skip if already loaded
		if(this.facebookSdkLoaded) { return; }

		// Connect to SDK if we have permission
		if(AnalyticsCheck("social-media")){
			console.log('Consent for Facebook SDK');

			window.fbAsyncInit = this.onFacebookSdkLoaded.bind(this);

			this.fbConsent = true;
			
			(function(d, s, id){
				var js, fjs = d.getElementsByTagName(s)[0];
				if(d.getElementById(id)) {return;}
				js = d.createElement(s); 
				js.id = id;
				js.src = "//connect.facebook.net/en_US/sdk.js";
				fjs.parentNode.insertBefore(js, fjs);
			}(document, 'script', 'facebook-jssdk'));
		}
		else {
			console.log('No consent for Facebook SDK');
		}
	}

	// Facebook SDK loaded, now create the FB object
	this.onFacebookSdkLoaded = function() {
		// Skip if already loaded
		if(this.facebookSdkLoaded) { return; }

		this.facebookSdkLoaded = true;

		FB.init({
			// App ID is passed from backend into meta tag in <head>, grab it here.
			appId: this.fbAppId,
			xfbml: true,
			autoLogAppEvents: false,
			version: 'v10.0'
		});

		console.log('Facebook SDK loaded');
	}

	this.hasFbConsent = function() {
		return this.fbConsent && typeof FB !== 'undefined';
	}
	
	this.share = function(e) {
		var clickedButton = $(e.currentTarget);
		var platform      = clickedButton.data('platform');
		var url           = clickedButton.data('shareUrl');
		var encodedUrl    = encodeURIComponent(url);
		var encodedSrc    = encodeURIComponent(window.location);

		console.log("Share.share()", platform || null, url || null);

		if(/Android|iPhone|iPad/i.test(navigator.userAgent) || Cookie.get("iPadOS")) {
			console.log("Share.share()", "mobile device");
			var mobile = true;
		}

		// Share to Facebook via SDK
		if(platform == 'facebook' && this.hasFbConsent()){
			console.log('Sharing to Facebook via SDK');
			FB.ui({method: "share", href: url}, resp => {});
		}
		// Share to Facebook by link
		else if(platform == 'facebook'){
			console.log('Sharing to Facebook via link: ' + 'https://www.facebook.com/sharer/sharer.php?u=' + encodedUrl);
			window.open('https://www.facebook.com/sharer/sharer.php?u=' + encodedUrl, '_blank');
		}
		// Share to Messenger via SDK
		else if(platform == 'messenger' && this.hasFbConsent() && !mobile){
			console.log('Sharing to Messenger via SDK (desktop only)');
			FB.ui({method: "send", link: url}, resp => {});
		}
		// Share to Messenger by link
		else if(platform == 'messenger' && mobile){
			console.log('Sharing to Messenger via link (mobile): ' + 'fb-messenger://share/?link=' + encodedUrl + '&app_id=' + this.fbAppId);
			window.open('fb-messenger://share/?link=' + encodedUrl + '&app_id=' + this.fbAppId, '_blank');
		}
		else if(platform == 'messenger'){
			console.log('Sharing to Messenger via link (desktop): ' + 'http://www.facebook.com/dialog/send?app_id=' + this.fbAppId + '&link=' + encodedUrl + '&redirect_uri=' + encodedSrc);
			window.open('https://www.facebook.com/dialog/send?app_id=' + this.fbAppId + '&link=' + encodedUrl + '&redirect_uri=' + encodedSrc, '_blank');
		}
		else if(platform == 'email') {
			var subject = clickedButton.data('subject');
			var body = $('#js-share-email-content').val();
			window.open('mailto:?subject=' + encodeURIComponent(subject) + '&body=' + encodeURIComponent(body), '_self');
		}

		this.track(url, platform);
	}

	this.track = function(url, type) {
		url = url || $(".js-share-link-input").val() || window.location.href
		console.log("Share.track()", url, type);
		$.ajax("/share/track", { method: 'POST', data: {url: url, type: type} });
	}

	var self = this;
	self.init(modal);
}