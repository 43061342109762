/**
 * Generic loading button.
 *
 * Mainly used on forms which you don't want the user to submit multiple times, or forms which use AJAX that need to show a loading state.
 * Call 'disable' to disable the button and show the loading state, call 'enable' to restore it to its original state.
 *
 * Add data-loading-text="YOUR LOADING TEXT" to the button use a different loading message.
 *
 * It will work with both <input> / <button> elements.
 *
 * @author Daniel Moffat
 */

'use strict';

const buildIcon = require("Scripts/common/build-icon");

module.exports = LoadingButton;

function LoadingButton(el) {
	this.init = function() {
		this.button = $(el);
		this.completed = false;
		
		if(!this.button.length) {
			console.warn('Couldn\'t find button', el);
			return;
		}

		this.isInputEl   = this.button.prop('tagName').toLowerCase() === 'input';
		this.loadingText = this.button.data('loadingText') || (this.isInputEl ? 'Loading...' : buildIcon('fa-spinner', {classes:'fa-spin'}));
		
		if(this.isInputEl) {
			this.oldButtonText = this.button.val();
		} 
		else {
			this.oldButtonText = this.button.html();
		}
	};

	// Provide external access to the internal element
	this.el = function() {
		return this.button;
	};

	this.enable = function() {
		if(!this.completed) {
			this.setButtonText(this.oldButtonText);
			this.button.prop('disabled', false);
			this.button.css('width', "");
		}
	};

	this.disable = function() {
		this.fixWidth();
		this.setButtonText(this.loadingText);
		this.button.prop('disabled', true);
	};

	this.success = function() {
		this.setButtonText(this.isInputEl ? 'Success!' : buildIcon('fa-check'));
		this.completed = true;
	}

	this.setButtonText = function(text) {
		if(this.isInputEl) {
			this.button.val(text);
		} 
		else if (!this.button.has("img").length) {
			this.button.html(text);
		}
	};

	this.fixWidth = function() {
		// This hard-codeds the width so button itself stays the same, must happen before text change. Ignore if .btn-block as will be 100% width
		if(!this.button.hasClass('btn-block')) {
			this.button.outerWidth(Math.ceil(this.button.outerWidth()));
		}
	};

	this.init();
}