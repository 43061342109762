const Cookie = require('Vendor/js-cookie');

class Zakat {
	constructor() {
		this.calc           = $("#calculator");
		this.totalInput     = $("#calc-total");
		this.manualInput    = $("#calc-manual");
		this.thresholdInput = $("#calc-threshold");
		this.inputs         = this.calc.find("input, select");
		
		this.bindEvents();
	}

	bindEvents() {
		this.calc.on('submit', function(e){ console.log("Submit stopped"); e.preventDefault(); });
		this.inputs.on('change keyup', this.calculate.bind(this));
		this.manualInput.on('change keyup', this.setManually.bind(this));
	}

	calculate() {
		console.log("Zakat.calculate()");
		
		let total     = 0;
		let perc      = parseFloat(this.totalInput.data('perc'));
		let threshold = parseFloat(this.thresholdInput.val());

		this.inputs.each((k,v) => {
			let input = $(v);
			let value = parseFloat(input.val()) || 0;

			if(!input.is('[readonly]')) {
				if(input.data('negative')){
					total -= value;
				}
				else {
					total += value;
				}
			}
		});

		total = total < 0 || total < threshold ? 0 : (total * perc / 100).toFixed(2);

		this.totalInput.val(total);
		this.cookie(total);
	}

	setManually() {
		console.log("Zakat.setManually()", this.manualInput.val());
		this.cookie(this.manualInput.val());
	}

	cookie(value) {
		const cookieName = "gayl-donation-amount";

		if(value > 0){
			Cookie.set(cookieName, value, {expires:1/24});
		}
		else {
			Cookie.remove(cookieName);
		}
	}
}

module.exports = Zakat;