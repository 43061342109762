'use strict';

export default class ImageUploadSimple {
	constructor(options) {
		this.maxSize    = 10000000;
		this.width      = options.width || false;
		this.height     = options.height || false;
		this.fileTypes  = options.fileTypes || ['image/jpeg','image/png','image/bmp','image/gif','image/webp'];
		this.callback   = options.callback || false;

		const container = options.container || $("main");
		
		this.ui = {
			dnd:                 container.find('.dragdrop-file'),
			errorContainer:      container.find('.js-error-container'),
			imageInput:          container.find('.js-image-input'),
			imgPreview:          container.find('.js-img-preview'),
			imgPreviewContainer: container.find('.js-img-preview-container'),
		};

		this.invalidTypeErrorMsg = this.ui.imageInput.data('invalidTypeErrorMsg');
		this.tooBigMsg           = this.ui.imageInput.data('tooBigMsg');
		this.tooSmallMsg         = this.ui.imageInput.data('tooSmallMsg');
		this.labelText           = container.find('.dragdrop-file-label').html();

		this.bindEventHandlers();
	}

	bindEventHandlers() {
		console.log("ImageUploadSimple.bindEventHandlers()")

		this.ui.imageInput.on('change', this.onChangeImage.bind(this));

		this.ui.imageInput.on('drag dragstart dragend dragover dragenter dragleave drop', e => {
			this.ui.dnd.addClass("dragdrop-file-hover");
		})
		.on('dragleave dragend drop', e => {
			this.ui.dnd.removeClass('dragdrop-file-hover');
		});
	}

	onChangeImage() {
		console.log("ImageUploadSimple.onChangeImage()");

		this.reset(); // Always reset the state after an image has been selected.

		this.selectedFile = this.ui.imageInput.get(0).files[0];

		if(!this.selectedFile) { // User cleared the image input
			return;
		}

		this.updateFileInputLabel('Verifying image');

		// Is file format ok?
		if(!this.isValidContentType(this.selectedFile)) {
			this.showErrorMsg(this.invalidTypeErrorMsg);
			return;
		}

		// Is file size ok?
		if(!this.isValidSize(this.selectedFile)) {
			this.showErrorMsg(this.tooBigMsg);
			return;
		}

		// Are dimensions ok? Ideally this would work like the above, 
		// but to do so we'd need to use async/await which isn't supported
		// by the version of Webpack we currently have (v4)
		this.isValidDimensions(this.selectedFile);
	}

	isValidContentType(file) {
		return this.fileTypes.indexOf(file.type) > -1;
	}

	isValidSize(file) {
		return file.size <= this.maxSize;
	}

	isValidDimensions(file) {
		new Promise((resolve, reject) => {
			let reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = (e) => {
				let image = new Image();
				image.src = e.target.result;
				image.onload = (e) => {
					let width = e.target.width;
					let height = e.target.height;
					resolve(!this.width || width >= this.width) && (!this.height || height >= this.height);
				};
			};
		}).then(passed => {
			if(passed) {
				this.postVerify();
			}
			else {
				this.showErrorMsg(this.tooSmallMsg);
			}
		});
	}

	postVerify() {
		this.updateFileInputLabel('Selected: ' + this.selectedFile.name);
		this.displayImage();
	}

	// Updates the file input label to the given message
	updateFileInputLabel(msg) {
		var msg = msg || this.labelText;
		var labelEl = this.ui.imageInput.next();
		labelEl.html(msg);
	};

	displayImage() {
		console.log("ImageUploadSimple.displayImage()");

		// Update the image element
		this.ui.imgPreview.attr('src', URL.createObjectURL(this.selectedFile));

		// Reveal the container with preview image in - it may have been hidden previously.
		this.ui.imgPreviewContainer.addClass('selected').collapse('show');
	}

	reset() {
		console.log("ImageUploadSimple.reset()");
		this.ui.imgPreview.attr('src', '');
		this.ui.imgPreviewContainer.collapse('hide');
		this.clearErrorMsg();
		this.updateFileInputLabel();
	}

	showErrorMsg(msg) {
		console.log("ImageUploadSimple.showErrorMsg()", msg);
		this.ui.errorContainer.empty().text(msg).show();
		this.updateFileInputLabel();
	}

	clearErrorMsg() {
		console.log("ImageUploadSimple.clearErrorMsg()");
		this.ui.errorContainer.empty().hide();
	}

	addImagesToFormData(formData, prepend) {
		console.log("ImageUploadSimple.addImagesToFormData()", prepend);

		if(this.selectedFile) {
			prepend = prepend != null ? prepend + "." : "";
			formData.append(prepend, this.selectedFile);
		}

		return formData;
	}
};