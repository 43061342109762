// Simple way to fetch content via AJAX and inject into page
// Aim is that it will work for most simple pages, including pagination, avoiding lots of near-identical JS classes
// 
// Options: 
// - element, container
// - callback, called after a fetch has been performed
// - data, data for your path variables/query string. E.g. if your endpoint is /my-endpoint/{blah}, a data value of 
// {blah: 1234} would result in the URL /my-endpoint/1234?blah=1234
// - endpoint, the endpoint which is called to fetch data
// - json, set to true if your endpoint returns raw json
// - method, the method your endpoint is called with
// - modal, set to a jQuery object containing your modal if your fetcher is inside a modal, it will get shown when the fetch completes
// - refreshed, no idea
// - trigger, either a LoadingButton/jQuery object/Node object which triggers the fetching
// - noClear, looks like it stops the fetcher content being emptied between fetches
// 
// Usage:
// - For using in a modal, see gayl.wiki/Velocity-snippets.md > Loading dynamic content into a modal
// - Everything else, just search for 'Fetcher'

/*
	TODO:
		- Make it triggerable by element
		- Link to search form so that it can be searchable (this would eliminate a lot of repetitive JS!)
*/

import bsCollapse    from 'Scripts/common/bs-collapse';
import LoadingButton from 'Scripts/common/loading-button';
import Pagination    from 'Scripts/common/pagination';
import { miuri }     from 'Vendor/miuri.js';

export default class Fetcher {
	constructor(options) {
		if(options) {
			if(!!this.setup(options)){
				this.bindEvents(); // Don't want to bind events if setup failed
			}
		} else {
			$(".js-fetcher").each((i,element) => {
				new Fetcher({"element": $(element)});
			});
		}
	}

	bindEvents() {
		$(window).on("popstate", this.onPopstate.bind(this));
	}

	// Set up the object
	setup(options) {
		options = options || this.options || {};
		let element = $(options.element);
		
		if(!element || !element.length) {
			console.warn("Fetcher.setup()", "No element found")
			return false;
		}
		
		let content    = $(element.find(".js-fetcher__content"));
		let spinner    = $(element.find(".js-fetcher__spinner"));

		this.options = {
			callback:     options.callback || null,
			preClickPage: options.preClickPage || null,
			content:      content.length ? content : null,
			data:         options.data || {},
			element:      element,
			endpoint:     options.endpoint || content.data('endpoint'),
			json:         options.json || false,
			method:       options.method || content.data('method') || 'GET',
			modal:        options.modal || false,
			refreshed:    options.refreshed || false,
			scroll:       options.scroll || content.data('scroll') || false,
			spinner:      spinner.length ? spinner : null,
			trigger:      options.trigger ? options.trigger.el ? options.trigger : new LoadingButton($(options.trigger)) : null,
			url:          this.url(),
			noClear:      options.noClear || false
		}

		new Pagination({ container: element, onClickPage: this.onClickPage.bind(this)});

		// If content is empty then we trigger AJAX on page load. Can be forced (options.force is not added to the options object)
		if((options.force || this.options.modal || content.is(":empty")) && !this.options.refreshed) {
			this.fetch();
		} else {
			this.spinner(false);
		}
	}

	// Do the AJAX call
	fetch(scroll) {
		if(!this.options) return;

		this.options.url = this.url(); // Need to set here to ensure it's picked up
		this.addUrlVariables(); // Changes /foo/{id}/edit to /foo/123/edit

		let options      = this.options;
		let endpoint     = new miuri(options.endpoint);
		let query        = {...this.options.data, ...this.options.url.query()};

		endpoint.query(query);

		this.spinner(true);

		// If we scroll back to the top, then best to calculate after the collapse event
		if(scroll) {
			this.options.element.on("shown.bs.collapse", () => {
				let rect = this.options.element[0].getBoundingClientRect();
				if(rect.top < 0 || rect.bottom > $(window).height()) {
					this.options.element[0].scrollIntoView({behavior:'smooth'});
				}
			});
		}

		if(this.options.spinner) {
			bsCollapse(this.options.element).show();
		}

		if(options.trigger) {
			options.trigger.disable();
		}

		fetch(endpoint.toString(), {
			method: options.method,
		})
		.then(response => {
			if(response.ok) {
				return this.options.json ? response.json() : response.text();
			}
			throw new Error("Not 2xx response")
		}, error => {
			console.error('Error:', error);
		})
		.then(data => {
			let options = this.options;
			let content = options.content;

			if(this.options.json) {
				let json = data.payload.prettyJson || data.payload.json || data.payload || data;
				json = JSON.stringify(JSON.parse(json), null, 2);
				content.text(json);
			} else {
				content.html(data);
			}

			content.toggleClass('hide', false);
			
			options.refreshed = true; // Stops auto-fetching, which could theoretically happen if blank content returned - that would cause a loop
			this.setup(options);

			if(options.modal) {
				options.modal.modal('show');
			}

			if(options.callback) {
				options.callback(this);
			}

			if(options.trigger) {
				options.trigger.enable();
			}
		});
	}

	// Show hide the spinner. Don't use Bootstrap collapse as if the load is really quick then it tries to collapse(hide) before collapse(show) is finished
	spinner(preFetch) {

		// can avoid clearing fetched content with this option.
		if (this.options.noClear) {
			return;
		}

		let spinner = this.options.spinner;
		let content = this.options.content;
		let height  = 0;

		if(spinner) {
			if(content && preFetch) {
				height = content.outerHeight();
				content.toggleClass('hide', preFetch);
			}

			spinner.toggleClass('hide', !preFetch);
			if(height > 0) {
				spinner.css('min-height', height + 'px');
			} else {
				spinner.css('min-height', 'unset');
			}
		}
	}

	// Reset page to 1, without loading the page. Useful when you need to do
	// custom loading, e.g. setting page to 1 then searching using a parameter.
	clear() {
		this.setUrl(1);
	}

	// Reset page to 1 and then load the page
	reset() {
		this.onClickPage(1);
	}

	// Triggered by Pagination class. Do a page click, including loading
	onClickPage(page) {
		if (this.options && this.options.preClickPage) {
			this.options.preClickPage();
		}
		this.setUrl(page); // Must go before fetch
		this.fetch(this.options.scroll);
	}

	// Sets browser URL according to the page parameter.
	setUrl(page) {
		this.options.url.query('page', page);
		if(!this.options.element.hasClass("modal") || !this.options.modal) {
			history.pushState("", document.title, this.options.url);
		}
	}

	// If pressing back/forward
	onPopstate() {
		this.setup();
		this.fetch(this.options.scroll);
	}

	// Get the current URL as a miuri object
	url() {
		return new miuri(window.location.href);
	}

	// Populate {foo} variables in the endpoint URL
	addUrlVariables() {
		let endpoint = this.options.endpoint;
		let regex = /(?:\{)([a-zA-Z0-9]+)(?:\})/g;
		let result;
		while ((result = regex.exec(endpoint)) !== null) {
			endpoint = endpoint.replace(result[0], ""+this.options.data[result[1]]);
		}
		this.options.endpoint = endpoint;
	}

	// Remove all HTML from the fetcher container
	empty() {
		this.options.content.empty();
	}
}