/**
 * A wrapper for JS's native fetch() which deals with errors
 * 
 * Example usage:
 * 
 * const { success, result } = await fetchJson($(e.currentTarget).attr('action'), {
 *		method: 'POST',
 *		body: this.getFormData(),
 * });
 *
 * if(!success) {
 * 		// Do something (e.g. showErrorBar() using show-error-bar.js) 
 * }
 */

import AnalyticsEvent from 'Scripts/common/analytics-event';

export default async function fetchJson(endpoint, fetchArgs) {
	try {
		const response = await fetch(endpoint, fetchArgs || {});

		if(!response.ok) {
			throw new Error(response.status);
		}

		const jsonResponse = await response.json();

		return {
			success: true, 
			result: jsonResponse,
		};

	} catch (error) {
		AnalyticsEvent.sentry(error.message, error)

		return {
			success: false,

			// This mimics our ValidationResponse and ApiResponse objects to help prevent against JS errors
			result: {
				success: false,
				errors: [],
				payload: {
					validation: [],
				}
			},
		}
	}
}
