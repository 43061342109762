// Shows a modal where a user can choose their GAYL email and charity data sharing consents. 
// Shown after a user signs up using Facebook, or when an existing user logs in who hasn't done their consents yet. 
// Refactored from email-consents.js, which was pretty dreadful code originally.
// @author Matthew Norris

const LoadingButton = require('Scripts/common/loading-button');
const ModalQueue    = require("Scripts/common/modal-queue");

module.exports = GdprConsentModal;

function GdprConsentModal() {

	// Initialise and show consents modal if the HTML has been included in the template.
    this.init = function() {
        let modal = $("#js-consent-modal");

        if (modal.length == 0) {
            return // If there's no modal, don't bother trying to initialise.
        }

	    this.ui = {
            btn:   new LoadingButton($(".js-consent-submit")),
            form:  $("#js-consent-form"),
            modal: modal,
            skip:  new LoadingButton($(".js-consent-skip")),
        }

	    new ModalQueue(); // attaches to window.modalQueue, there should be only one of these objects.

        // check at the beginning for need to un-disable the submit button (if pre-selected for some reason)
        this.onChange();

        // attach listeners
        this.ui.form.find("input:radio").change(this.onChange.bind(this));
        this.ui.skip.el().on("click", this.skip.bind(this));
        this.ui.form.on("submit", this.onSubmit.bind(this));

        // Queue modal to display
        window.modalQueue.add({
            id: "js-consent-modal", 
            priority: 1
        });
    }

    // Check radio buttons, undisable when both are clicked, otherwise disable the button
    this.onChange = function() {
        console.log("GdprConsentModal.onChange");

        // If we're using a single checkbox instead of yes/no, 
        // then both of these will be 0 which is fine as we want button enabled
        var nRadios = this.ui.form.find("input:radio[value=1]").length;
        var nChecked = this.ui.form.find("input:radio:checked").length;

        this.ui.btn.el().attr("disabled", nRadios != nChecked);
    }

    // Listen for form submit
    this.onSubmit = function() {
        console.log("GdprConsentModal.onSubmit");

        this.ui.btn.disable();

        $.ajax({
            url: "/gdpr-consent-modal",
            method: "POST",
            data: this.ui.form.serialize(),
            success: data => {
                if (!data.success) {
                    // user is not logged in - so take them to the login page
                    window.location.href = "/login";
                }

                // Successfully saved the email consents. Hide the modal
                this.ui.modal.modal("hide");
            }
        });

        return false;
    }

    // Skip email consents. posts off AJAX to keep a record of when user skipped it
    this.skip = function() {
        this.ui.skip.disable();
      
        $.ajax({
            url: "/gdpr-consent-modal-skip",
            method: "POST",
            success: data => {
                this.ui.modal.modal("hide");
            }
        })
    }

    this.init()
};