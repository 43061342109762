/**
 * Function for loading a JavaScript file. Returns a promise so you can do stuff after it's finished loading.
 * The promise will be rejected if the script failed to load.
 *
 * E.g. loadScript('/my-script.js').then(() => console.log('Script loaded!'));
 */

const loadScript = (src) => {
	return new Promise((resolve, reject) => {
		const script = document.createElement('script');
		script.src = src;
		script.type = 'text/javascript';
		script.onerror = () => {
			reject();
		};
		script.onload = () => {
			resolve();
		};
		document.body.appendChild(script);
	});
}

module.exports = {
	loadScript: loadScript
};