/**
 * 	Base class for streams that are displayed on fundraising pages.
 *
 *	Each provider should override methods as needed to perform any bespoke steps, for example, to load an SDK to play the video.
 *
 *  Lifecycle:
 *  - Page loads <- setup() called
 *  - Page polls for stream being online
 *    - User goes online <- firstLoad() called
 *  - Page polls again for stream being online
 *    - User is offline <- cleanup() called
 */

class Stream {
	constructor() {}
	
	// Perform any setup before the stream can be displayed - this is called when the page is loaded.
	// Important: This should return a promise with resolves when setup completes, and reject if the setup failed.
	setup() {
		throw new Error('You need to override Stream#setup in your subclass.');
	}

	// Called the first time the stream should be displayed on the page - when the stream first goes online.
	// Stream info contains the stream source and any other data needed to render it on the page.
	firstLoad(streamInfo) {
		throw new Error('You need to override Stream#firstLoad in your subclass.');
	}

	// Perform any cleanup once the stream is not longer displayed - this is called when the stream goes offline.
	cleanup() {
		throw new Error('You need to override Stream#cleanup in your subclass.');
	}
}

module.exports = Stream;