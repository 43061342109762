/**
 * Event list component. Lists organised events and lets you page through them and perform searches.
 *
 * Used in the internal admin area (/internal/events) and in the create page process.
 *
 * @author Daniel Moffat
 */
'use strict';

const LoadingButton = require('Scripts/common/loading-button');
const miuri         = require('Vendor/miuri.js').miuri;
const Pagination    = require('Scripts/common/pagination');
const Suggestion    = require('Scripts/common/suggestion').default;

module.exports = EventList;

function EventList() {
	this.init = function() {
		this.ui = {
			root:            $('.js-event-list'),
			content:         $('.js-event-list-content'),
			loading:         $('.js-event-list-loading'),
			searchForm:      $('.js-event-list-search'),
			searchFormBtn:   new LoadingButton($('.js-submit')),
			searchFormInput: $('.js-search-term'),
			searchStartDate: $('.js-search-start'),
			searchEndDate:   $('.js-search-end'),
			searchType:      $('.js-search-type'),
		};

		// Where AJAX requests get made to fetch query results.
		this.endpoint = this.ui.root.data('endpoint');

		this.pagination = new Pagination({
			container: $('.js-event-list'),
			onClickPage: this.onClickPage.bind(this)
		});

		this.query = {
			'query': this.ui.searchFormInput.val(),
			'page': this.pagination.currentPage,
			'rows': this.ui.root.data('rows') || 10,
			'eventType': this.ui.searchType.val() || null,
			'startDate': this.ui.searchStartDate.val()
		};

		this.bindEventHandlers();

		new Suggestion($('.js-set-event-term'), this.onSearch.bind(this));
	};

	this.bindEventHandlers = function() {
		this.ui.searchForm.on('submit', this.onSearch.bind(this));
	};

	this.onSearch = function(e) {
		console.log("EventList.onSearch()");
		
		e.preventDefault();
		this.ui.searchFormBtn.disable();
		var self = this;
		
		this.query.query     = this.ui.searchFormInput.val();
		this.query.startDate = this.ui.searchStartDate.val();
		this.query.endDate   = this.ui.searchEndDate.val();
		this.query.eventType = this.ui.searchType.val();
		this.query.page      = 1; // Must reset the page back to 1, otherwise the query will contain the current page

		this.search().then(function() {
			self.ui.searchFormBtn.enable();
		});
	};

	this.onClickPage = function(page) {
		console.log("EventList.onClickPage(" + page + ")");

		this.query.page = page;
		this.ui.loading.show();
		this.ui.content.hide();
		this.search();
	};

	this.search = function() {
		var self = this;
		
		return this.loadResults(this.query).then(function(resp) { // resp is raw HTML
			self.ui.loading.hide();
			self.ui.content.empty().html(resp).show();
			self.pagination.bindEventHandlers(); // Rebind the pagination's event handlers
			return resp;
		});
	};

	this.loadResults = function(query) {
		console.log("EventList.loadResults()");

		var endpoint = this.endpoint;
		var queries = ['page','query','rows','startDate','endDate','eventType'];

		var url = new miuri(location.protocol + '//' + location.host + location.pathname); // Necessary evil, just using new miuri() causes issues when trying to set params back to blank

		$.each(queries, function(i,v) {
			if(query[v]) {
				endpoint += (i == 0 ? '?' : '&') + v + '=' + encodeURIComponent(query[v]);
				url.query(v, query[v]);
			}
		});

		window.history.pushState({},"", url.toString());

		return $.ajax({
			url: endpoint += '&callingUrl=' + window.location.pathname
		});
	};

	this.init();
}