/**
 * Fundraising page list component.
 *
 * Used in the account section (/account/fundraising-pages) and on the public charity page which lists fundraising pages.
 *
 * @author Daniel Moffat
 */
'use strict';

const LoadingButton = require('Scripts/common/loading-button');
const Pagination    = require('Scripts/common/pagination');

module.exports = PageList;

function PageList(root) {
	this.init = function(root) {
		this.ui = {
			root: root,
			content: root.find('.js-page-list-content'),
			loading: root.find('.js-page-list-loading')
		};

		this.pagination = new Pagination({
			container: this.ui.root,
			onClickPage: this.onClickPage.bind(this)
		});

		this.endpoint = this.ui.root.data('endpoint');
		this.page = this.pagination.currentPage;
		this.rows = this.ui.root.data('rows') || 10;
	};

	this.onClickPage = function(page) {
		console.log('Clicked page: ' + page);
		this.page = page;
		this.ui.content.parent().css({'height': this.ui.content.height()}); // Hacky, to stop CSS columns-* moving stuff about
		this.ui.loading.show();
		this.ui.content.hide();
		this.search();
		this.ui.content.parent().removeAttr('style'); // And this undoes the hack (if not removed then causes issues)
	};

	this.search = function() {
		var self = this;
		return this.loadResults({
			page: this.page,
			rows: this.rows
		}).then(function(resp) { // resp is raw HTML
			self.ui.loading.hide();
			self.ui.content.empty().html(resp).show();
			self.pagination.bindEventHandlers(); // Rebind the pagination's event handlers
			return resp;
		});
	};

	this.loadResults = function(params) {
		var endpoint = this.endpoint;
		if(params.page) {
			endpoint += '?page=' + params.page;
		}
		if(params.rows) {
			endpoint += '&rows=' + params.rows;
		}
		return $.ajax({
			url: endpoint
		});
	};

	if(root) {
		this.init(root);
	} else {
		$('.js-page-list').each((i, el) => {
			new PageList($(el));
		});
	}
}