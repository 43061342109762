/**
 * Generic pagination controls.
 *
 * Hooks up events to the buttons and lets the caller specify a callback so they can control what happens when buttons are pressed. This
 * just prevents everything that uses pagination from reimplementing this. It requires you to use the common pagination.vm template to work.
 *
 * @author Daniel Moffat
 */

module.exports = function Pagination(options) {
	this.init = function() {
		// Container element, needed if you have mulitple pagination controls on the same page / in the same module group.
		this.container = options.container || $(document);
		this.onClickPageCallback = options.onClickPage || function() {};
		this.bindEventHandlers();
		this.currentPage = parseInt(this.ui.root.data('initialPage')) || 1;
	};

	this.selectElements = function() {
		this.ui = {
			root: this.container.find('.js-pagination'),
			pageBtn: this.container.find('.js-select-page')
		};
	};

	this.bindEventHandlers = function() {
		this.selectElements();
		this.ui.pageBtn.on('click', this.onClickPage.bind(this));
	};

	this.onClickPage = function(e) {
		e.preventDefault();
		var selectedPage = parseInt($(e.currentTarget).data('page'));
		// Don't load results if the same page was clicked..
		if(this.currentPage === selectedPage) {
			return;
		}

		// Scroll to the results (sometimes the div is hidden during the load, if so we scroll to its parent)
		var resultsContainer = $(this.ui.root.data('resultsContainer'));
		resultsContainer = resultsContainer || this.container;
		resultsContainer = $(resultsContainer).is(":visible") ? resultsContainer : resultsContainer.parent();

		if(resultsContainer.length){
			$("html, body").animate({scrollTop: resultsContainer.offset().top}, "slow");
		}

		this.onClickPageCallback(selectedPage);
		this.currentPage = selectedPage;
	};

	// Update paginator's internal page number, important when resetting paging from a function, 
	// e.g. when changing search terms
	this.changePage = function(pageNum) {
		this.currentPage = pageNum;
	}

	this.init();
}