/**
 * JavaScript for the edit fundraising page form.
 * 
 * NB. StreamSelector has been commented as currently only Twitch is supported, therefore just using a standard input with no JS needed
 *
 * @author Daniel Moffat
 */
'use strict';

const AjaxFormErrorHandler = require('Scripts/common/ajax-form-error-handler');
const ImageUploadCrop      = require('Scripts/common/image-upload-crop').default;
const ImageUploadGallery   = require('Scripts/common/image-upload-gallery').default;
const LoadingButton        = require('Scripts/common/loading-button');
//const StreamSelector       = require('Scripts/donate/stream-selector').default; // Uses ES6 module syntax
const TextAreaExpand       = require('Scripts/common/text-area-expand');

module.exports = EditFundraisingPageForm;

function EditFundraisingPageForm() {
	this.init = function() {
		this.ui = {
			form: $('.js-edit-fundraising-page-form'),

			// Inputs
			inputEventDate:      $('#eventDate'),
			inputEventStartDate: $('#eventStartDate'),
			inputEventEndDate:   $('#eventEndDate'),
			inputTarget:         $('#target'),
			inputOwner:          $('#ownerName'),

			// Toggles
			toggleDate:         $('.js-toggle-date'),
			toggleDateRange:    $('.js-toggle-date-range'),
			toggleTarget:       $('.js-toggle-target'),
			toggleInMemory:     $('.js-toggle-in-memory'),

			dateContainer:      $('.js-date-container'),
			dateRangeContainer: $('.js-date-range-container'),

			inMemoryContainer:  $('.js-in-memory-container'),

			submitBtn: new LoadingButton($('.js-submit'))
		};

		// The banner
		this.image = new ImageUploadCrop({
			width:     1170,
			height:    350,
			restrict:  true,
		});

		// The banner gallery 
		this.gallery = new ImageUploadGallery({
			cropper: this.image,
			preview: $(".js-crop-result"),
			previewContainer: $(".js-crop-result-container"),
		});

		this.errorHandler = new AjaxFormErrorHandler({ useClasses: true });
		// this.streamSelector = new StreamSelector();

		// This makes <textarea> inputs expand as you type
		new TextAreaExpand();

		this.bindEventHandlers();
	};

	this.bindEventHandlers = function() {
		this.ui.form.on('submit', this.onSubmit.bind(this));

		this.ui.toggleDate.on('change', this.onToggleDate.bind(this));
		this.ui.toggleDateRange.on('change', this.onToggleDateRange.bind(this));
		this.ui.toggleTarget.on('change', this.onToggleTarget.bind(this));
		this.ui.toggleInMemory.on('change', this.onToggleInMemory.bind(this));
	};

	// Allow user to disable the date
	this.onToggleDate = function(e) {
		var disabled = this.ui.toggleDate.is(":checked");

		var inputEventDate      = this.ui.inputEventDate;
		var inputEventStartDate = this.ui.inputEventStartDate;
		var inputEventEndDate   = this.ui.inputEventEndDate;

		if(disabled) {
			inputEventDate.data('value', inputEventDate.val() || '');
			inputEventStartDate.data('value', inputEventStartDate.val() || '');
			inputEventEndDate.data('value', inputEventEndDate.val() || '');
		}

		inputEventDate.prop('disabled', disabled).val(disabled ? '' : inputEventDate.data('value') || '');
		inputEventStartDate.prop('disabled', disabled).val(disabled ? '' : inputEventStartDate.data('value') || '');
		inputEventEndDate.prop('disabled', disabled).val(disabled ? '' : inputEventEndDate.data('value') || '');
	}

	// Allow user to switch between single date and start/end dates
	this.onToggleDateRange = function() {
		var toggleDate = this.ui.toggleDate;
		if(toggleDate.is(":checked")) {
			toggleDate.prop('checked', false).trigger('change');
		}

		var isRange    = this.ui.toggleDateRange.is(":checked");
		var singleDate = this.ui.inputEventDate;
		var rangeDates = this.ui.inputEventStartDate.add(this.ui.inputEventEndDate);

		if(isRange){
			rangeDates.val(singleDate.val());
		} else {
			singleDate.val(rangeDates.first().val());
		}

		singleDate.prop('disabled', isRange);
		rangeDates.prop('disabled', !isRange);

		this.ui.dateContainer.collapse(isRange ? 'hide' : 'show');
		this.ui.dateRangeContainer.collapse(isRange ? 'show' : 'hide');
	};

	// Allow user to disable the fundraising target. Removes any existing value but remembers it so you can toggle
	this.onToggleTarget = function(e) {
		var disabled = this.ui.toggleTarget.is(":checked"); 
		var input = this.ui.inputTarget; 
		
		input.prop('disabled', disabled);

		if(disabled){
			input.data('value',input.val()).val('');
		}
		else {
			input.val(input.data('value') || '');
		}
	};

	this.onToggleInMemory = function(e) {
		console.log("EditFundraisingPageForm.onToggleInMemory");
		let isInMemory = this.ui.toggleInMemory.is("[value=true]:checked");
		this.ui.inMemoryContainer.collapse(isInMemory ? "show" : "hide");
	}

	this.onSubmit = function(e) {
		e.preventDefault();

		var self = this;
		var endpoint = this.ui.form.attr('action');
		this.ui.submitBtn.disable();

		$.ajax({
			method: 'POST',
			url: endpoint,
			data: this.getFormData(),
			processData: false,
			contentType: false
		}).then(function(resp) {
			if(!resp.success) {
				self.errorHandler.handleErrors(resp.payload.validation);
				self.ui.submitBtn.enable();
			} else {
				// Remove any errors that were present + redirect
				self.errorHandler.clear();
				self.ui.submitBtn.success();
				window.location.href = resp.payload.redirectUrl;
			}
		});
	};

	/**
	 * Gets the form data for submission to the server - some of the data is not stored in input fields (it's in memory)
	 * so we must add the form data manually.
	 */
	this.getFormData = function() {
		let formData = new FormData(this.ui.form.get(0));
		formData = this.image.addImagesToFormData(formData, "images");
		// formData = this.streamSelector.addToFormData(formData);
		return formData;
	};

	this.init();
}