/**
 * Simple function to show/hide the mini-nav
 * Uses a timer so that it's not calculating it for every single scroll
 */

module.exports = MiniNav;

function MiniNav() {
	this.init = function() {
		this.nav = $(".mini-nav");
		if(!this.nav.length) return;

		this.target = $(this.nav.data('target'));
		if(!this.target.length) return;

		this.scrollTimer;

		$(window).on("scroll", this.scroll.bind(this));

		$(window).trigger("scroll");
	};

	this.scroll = function(e) {
		if(!this.scrollTimer) {
			this.scrollTimer = setTimeout(() => {
				this.scrollTimer = null;

				let visible = this.isScrolledPast(this.target);
				
				this.nav.toggleClass("visible", visible);
				$("body").toggleClass(this.nav.data('bodyClass') || "has-mini-nav", visible);

			}, 200);
		}
	};

	this.isScrolledPast = function(el) {
		let distance = el.offset().top - $(window).scrollTop() - el.height();
		return distance < 0;
	};

	this.init();
}