/* Wrapper for Bootstrap collapse but with support for changing text of the button */

module.exports = function ShowHide() {
	$(document).on('click', '[data-toggle=showhide]', function (e) {

		e.preventDefault();
		var clickedElement  = $(e.currentTarget);
		var contentToToggle = $(clickedElement.data('target'));
		var currentText     = clickedElement.children('span:not(.fa-layers)').html();
		var replacementText = clickedElement.data('altText');
		var icon            = clickedElement.find('svg, i');
		var wasExpanded     = clickedElement.attr('aria-expanded');
		var $true           = 'true';
		var $false          = 'false';

		if(!contentToToggle.length) {
			return;
		}

		contentToToggle.collapse('toggle');

		if(replacementText && currentText) {
			clickedElement.children('span:not(.fa-layers)').html(replacementText);
			clickedElement.data('altText', currentText);
		}

		clickedElement.attr('aria-expanded', wasExpanded == $true ? $false : $true);
		contentToToggle.attr('aria-hidden', wasExpanded == $true ? $true : $false);

		// Change the icon
		if(icon) {
			var icons = [
				['fa-caret-up', 'fa-caret-down'],
				['fa-plus', 'fa-minus'],
				['fa-circle-plus', 'fa-circle-minus']
			];

			$.each(icons, function(key,iconClass) {
				$(icon).filter('.' + iconClass[0] + ', .' + iconClass[1]).toggleClass(iconClass[0] + ' ' + iconClass[1]);
			});
		}
	});
};