import LoadingButton from 'Scripts/common/loading-button';
import Pagination    from 'Scripts/common/pagination';

export default class UpdatesList {
	constructor() {
		this.ui = {
			root: $('.js-updates-list'),
			content: $('.js-updates-list-content'),
			loading: $('.js-updates-list-loading')
		};

		this.pagination = new Pagination({
			container: this.ui.root,
			onClickPage: this.onClickPage.bind(this)
		});

		this.endpoint = this.ui.root.data('endpoint');
		this.page = this.pagination.currentPage;
		this.rows = this.ui.root.data('rows') || 10;
		this.bindEvents();
	}

	bindEvents() {
		$(".js-reaction").on('click', this.clickReaction.bind(this));

		$(".activity-photo").on('click', e => {
			$(e.currentTarget).toggleClass("col-12");
		});
	}

	onClickPage(page) {
		console.log('UpdatesList.onClickPage()', page);
		this.page = page;
		this.ui.content.parent().css({'height': this.ui.content.height()}); // Hacky, but stops box decrease then increase in size
		this.ui.loading.show();
		this.ui.content.hide();
		this.search();
	}

	search() {
		return this.loadResults({
			page: this.page,
			rows: this.rows
		}).then(resp => { // resp is raw HTML
			this.ui.loading.hide();
			this.ui.content.empty().html(resp).show();
			this.pagination.bindEventHandlers(); // Rebind the pagination's event handlers
			this.ui.content.parent().removeAttr('style'); // And this undoes the height hack (if not removed then causes issues)
			this.bindEvents();
		});
	}

	loadResults(params) {
		var endpoint = this.endpoint;
		
		if(params.page) {
			endpoint += '?page=' + params.page;
		}
		
		if(params.rows) {
			endpoint += '&rows=' + params.rows;
		}

		return $.ajax({
			url: endpoint
		});
	}

	clickReaction(e) {
		console.log("UpdatesList.clickReaction", e);

		let button = $(e.currentTarget);
		let data = button.data();
		let counter = button.find(".js-reaction-counter");
		let text = button.find(".js-reaction-text");
		let icon = button.find("svg");
		let classOn = data.classOn;
		let classOff = data.classOff;
		let textOn = data.textOn;
		let textOff = data.textOff;
		let wasOn = button[0].ariaPressed == 'true';
		let oldCount = parseInt(counter.html() || 0);
		let newCount = oldCount + (wasOn ? -1 : 1);

		text.html(wasOn ? textOff : textOn);
		button.toggleClass(classOn, !wasOn);
		button.toggleClass(classOff, wasOn);
		button.attr("aria-pressed", !wasOn);
		counter.html(newCount > 0 ? newCount : '');
		icon.attr("data-prefix", wasOn ? "far" : "fas");

		// Send the ajax request
		fetch(data.endpoint + (wasOn ? "remove" : "add"));
	}
}