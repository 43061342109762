// Fees and fees calculator scripts

module.exports = function Fees() {
	this.init = function() {
		this.origAmount = 0;
		$('.js-amount').on('change keyup', this.calc.bind(this));
	}

	this.calc = function(e) {
		console.log("Fees.calc(" + $(e.currentTarget).val() + ")");

		// Get base amount
		var amount = $(e.currentTarget).val();
		var tableContainer = $('.js-table');

		// Stop if an invalid amount
		if(amount <= 0 || amount == "" || typeof amount == undefined) {
			console.log("Fees.calc()", "Invalid amount");
			tableContainer.collapse("hide");
			return;
		}
		else if(amount == self.origAmount) {
			console.log("Fees.calc()", "No change");
			return;	
		}

		self.origAmount = amount;

		var url = window.location.href;
		url += (url.includes('?') ? '&' : '?') +  "amount=" + amount;

		// Get the table content by AJAX and insert it in
		$.ajax(url).then(function(resp) {
			tableContainer.html(resp);
			tableContainer.collapse("show");
		});
	}

	this.init();
}